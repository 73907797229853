import { Module } from "vuex";
import {
  ClearChatRoom,
  ConnectChatRoom,
  CreateChatRoomRoom,
  GetChatRoomScrollUp,
  GetChatRoomMessages,
  GetChatRoomInfo,
  GetChatRoomList,
  GetChatRoomMembers,
  SetChatRoomScrollUpMessages,
  SetChatRoomId,
  SetChatRoomInfo,
  SetChatRoomList,
  SetChatRoomMembers,
  SetChatRoomUploadMessages,
  SetChatRoomType,
  SetConnectedChatRoom,
  PostChatRoomRead,
  SetNotifications,
  PostNotifications,
  IncreaseConnectCount,
  PostNotificationRead,
  SetChatRoomUnreadCount,
  SetNotificationUnreadCount,
  PostChatRoomReadFirstTime,
  PostNotificationReadFirstTime,
  PostNotificationReadByProject,
  PostChatRoomTitle,
  GetChatRoomSearchResults,
  SetSearchMessage,
  SetSearchResults,
  GetChatRoomSearchMessages,
  GetChatRoomScrollDown,
  SetChatRoomScrollDownMessages, DisconnectChatRoom
} from "@/typings/store";
import {
  ChatRoomListItem,
  ChatRoomMember, Message,
  Messages,
  Notifications
} from "@/typings/chatroom";
import { findToken } from "@/utils";

type ChatRoomState = {
  ws: WebSocket | null;
  type: string;
  chatRoomId: number | null;
  chatRoomInfo: ChatRoomListItem | null;
  members: ChatRoomMember[];
  list: ChatRoomListItem[];
  scrollUpMessages: Messages | null;
  scrollDownMessages: Messages | null;
  uploadMessages: Messages | null;
  searchMessages: Messages | null;
  notifications: Notifications | null;
  connectCount: number;
  chatRoomUnread: number;
  notificationUnread: number;
  catch: { type: string; value: any }[];
  searchResults: Message[];
};

const CHAT_ROOM_CODE = {
  CREATE_CHAT_ROOM: 300,
  GET_JOINED_MEMBER: 400,
  GET_CHAT_ROOM_INFO: 500,
  GET_CHAT_ROOM_LIST: 600,

  POST_CHAT_ROOM_SCROLL_UP_MESSAGE: 700, // Loading up.
  GET_CHAT_ROOM_SCROLL_UP_MESSAGE: 700,

  GET_CHAT_ROOM_UPLOAD_MESSAGE: 800, // POST CHAT ROOM MESSAGE used post api.

  POST_CHAT_ROOM_SCROLL_DOWN_MESSAGE: 2000, // Loading down.
  GET_CHAT_ROOM_SCROLL_DOWN_MESSAGE: 2000,

  POST_CHAT_ROOM_SEARCH_MESSAGE: 1900, // Loading search result message.
  GET_CHAT_ROOM_SEARCH_MESSAGE: 1900,

  GET_NOTIFICATION: 1100,

  POST_CHAT_READ_FIRST_TIME: 1300,
  GET_CHAT_ROOM_UNREAD_COUNT: 1300,

  POST_NOTIFICATION_READ_FIRST_TIME: 1400,
  GET_NOTIFICATION_UNREAD_COUNT: 1400,

  POST_NOTIFICATION_READ_BY_PROJECT: 1500,
  POST_CHAT_READ: 900,
  POST_NOTIFICATION: 1000,
  POST_NOTIFICATION_READ: 1200,
  POST_CHAT_ROOM_TITLE: 1600,

  POST_CHAT_ROOM_SEARCH_RESULTS: 1700,
  GET_CHAT_ROOM_SEARCH_RESULTS: 1700,

  GET_NEW_NOTIFICATION: 1800,
};

// eslint-disable-next-line
export const chatRoom: Module<ChatRoomState, any> = {
  state: {
    catch: [],
    ws: null,
    type: "ChatList",
    chatRoomId: null,
    chatRoomInfo: null,
    members: [],
    list: [],

    scrollUpMessages: null,
    scrollDownMessages: null,
    uploadMessages: null,

    searchResults: [],
    searchMessages: null,

    notifications: null,
    connectCount: 0,
    chatRoomUnread: 0,
    notificationUnread: 0,
  },
  mutations: {
    [SetChatRoomType](state, type) {
      state.type = type;
    },
    [SetConnectedChatRoom](state, ws: WebSocket) {
      state.ws = ws;
    },
    [SetChatRoomMembers](state, data: ChatRoomMember[]) {
      state.members = data;
    },
    [SetChatRoomList](state, data: ChatRoomListItem[]) {
      state.list = data;
    },
    [SetChatRoomInfo](state, data: ChatRoomListItem) {
      state.chatRoomInfo = data;
    },
    [SetChatRoomScrollUpMessages](state, data: Messages) {
      state.scrollUpMessages = data;
    },
    [SetChatRoomScrollDownMessages](state, data: Messages) {
      state.scrollDownMessages = data;
    },
    [SetChatRoomUploadMessages](state, data: Messages) {
      state.uploadMessages = data;
    },
    [SetNotifications](
      state,
      { type, data }: { type: string; data: Notifications }
    ) {
      if (type === "top") {
        if (state.notifications) {
          state.notifications = {
            links: state.notifications.links,
            next_curosr: state.notifications.next_curosr,
            results: [...data.results, ...state.notifications.results]
          };
        } else {
          state.notifications = data;
        }
      }
      if (type === "bottom") {
        if (state.notifications) {
          state.notifications = {
            links: data.links,
            next_curosr: data.next_curosr,
            results: [...state.notifications.results, ...data.results]
          };
        } else {
          state.notifications = data;
        }
      }
      if (type === "new") {
        state.notifications = data;
      }
    },
    [SetSearchResults](state, data: Message[]) {
      state.searchResults = data
    },
    [SetChatRoomId](state, id: number) {
      state.chatRoomId = id;
    },
    [ClearChatRoom](state) {
      state.scrollUpMessages = null;
      state.scrollDownMessages = null;
      state.uploadMessages = null;
    },
    [IncreaseConnectCount](state) {
      state.connectCount += 1;
    },
    [SetChatRoomUnreadCount](state, count: number) {
      state.chatRoomUnread = count;
    },
    [SetNotificationUnreadCount](state, count: number) {
      state.notificationUnread = count;
    },
    [SetSearchMessage](state, data: Messages) {
      state.searchMessages = data
    },
  },
  actions: {
    [ConnectChatRoom]({ dispatch, commit, state }) {
      const ws = new WebSocket(
        process.env.VUE_APP_WS + "/chat/?authorization=" + findToken()
      );

      ws.addEventListener("open", function() {
        // Get chat room list at first
        dispatch(GetChatRoomList);
        dispatch(PostNotifications);
        dispatch(PostChatRoomReadFirstTime);
        dispatch(PostNotificationReadFirstTime);

        console.log("Chat room connected successfully.");
      });
      ws.addEventListener("error", function(e) {
        console.log("Chat room had error: ", e);
      });
      ws.addEventListener("close", function(e) {
        console.log("Close chat room connection.", e);
        // if (state.connectCount < 5) {
        //   console.log("Reconnect chat room...");
        //   dispatch(ConnectChatRoom);
        //   commit(IncreaseConnectCount)
        // }
      });

      ws.addEventListener("message", function(e) {
        const msg = JSON.parse(e.data);

        switch (msg.code) {
          case CHAT_ROOM_CODE.CREATE_CHAT_ROOM:
            commit(ClearChatRoom);
            commit(SetChatRoomId, msg.data.id);
            commit(SetChatRoomType, "ChatRoom");
            return;
          case CHAT_ROOM_CODE.GET_JOINED_MEMBER:
            commit(SetChatRoomMembers, msg.data.joined_member);
            return;
          case CHAT_ROOM_CODE.GET_CHAT_ROOM_INFO:
            commit(SetChatRoomInfo, msg.data);
            return;
          case CHAT_ROOM_CODE.GET_CHAT_ROOM_LIST:
            commit(SetChatRoomList, msg.data);
            return;
          case CHAT_ROOM_CODE.GET_CHAT_ROOM_SCROLL_UP_MESSAGE:
            commit(SetChatRoomScrollUpMessages, msg.data);
            return;
          case CHAT_ROOM_CODE.GET_CHAT_ROOM_SCROLL_DOWN_MESSAGE:
            commit(SetChatRoomScrollDownMessages, msg.data);
            return;
          case CHAT_ROOM_CODE.GET_CHAT_ROOM_UPLOAD_MESSAGE:
            commit(SetChatRoomUploadMessages, msg.data);
            return;
          case CHAT_ROOM_CODE.POST_NOTIFICATION:
            commit(SetNotifications, { type: "bottom", data: msg.data });
            return;
          case CHAT_ROOM_CODE.GET_NOTIFICATION:
            commit(SetNotifications, { type: "new", data: msg.data });
            return;
          case CHAT_ROOM_CODE.GET_CHAT_ROOM_UNREAD_COUNT:
            commit(SetChatRoomUnreadCount, msg.data.chatroom_unread_count);
            return;
          case CHAT_ROOM_CODE.GET_NOTIFICATION_UNREAD_COUNT:
            commit(
              SetNotificationUnreadCount,
              msg.data.notification_unread_count
            );
            return;
          case CHAT_ROOM_CODE.GET_NEW_NOTIFICATION:
            commit(SetNotifications, { type: "top", data: msg.data });
            return;
          case CHAT_ROOM_CODE.POST_CHAT_ROOM_SEARCH_MESSAGE:
            commit(SetSearchMessage, msg.data);
            return;
          case CHAT_ROOM_CODE.GET_CHAT_ROOM_SEARCH_RESULTS:
            commit(SetSearchResults, msg.data.results);
            return;
          default:
            return;
        }
      });

      commit(SetConnectedChatRoom, ws);
      if (state.catch.length) {
        dispatch(PostNotificationReadByProject, state.catch[0].value);
      }
    },
    [DisconnectChatRoom]({ state }) {
      state.ws && state.ws.close()
    },
    [GetChatRoomMembers]({ state }, ids: number[] | null) {
      if (!state.ws) return;

      const message = {
        code: CHAT_ROOM_CODE.GET_JOINED_MEMBER,
        data: { joined_member: ids || [] }
      };
      state.ws.send(JSON.stringify(message));
    },
    [GetChatRoomList]({ state }) {
      if (!state.ws) return;

      const message = {
        code: CHAT_ROOM_CODE.GET_CHAT_ROOM_LIST
      };
      state.ws.send(JSON.stringify(message));
    },
    [CreateChatRoomRoom]({ state }, { ids }: { ids: number[] | null }) {
      if (!state.ws) return;

      const message = {
        code: CHAT_ROOM_CODE.CREATE_CHAT_ROOM,
        data: { joined_member: ids || [] }
      };
      state.ws.send(JSON.stringify(message));
    },
    [GetChatRoomInfo]({ state }, id: number) {
      if (!state.ws) return;

      const message = {
        code: CHAT_ROOM_CODE.GET_CHAT_ROOM_INFO,
        data: { chatroom_id: id }
      };

      state.ws.send(JSON.stringify(message));
    },
    [GetChatRoomScrollUp]({ state }, data: { id: number; cursor?: string }) {
      if (!state.ws) return;

      const message: {
        code: number;
        data: { chatroom_id: number; cursor?: string };
      } = {
        code: CHAT_ROOM_CODE.POST_CHAT_ROOM_SCROLL_UP_MESSAGE,
        data: { chatroom_id: data.id }
      };
      if (data.cursor) message.data.cursor = data.cursor;

      state.ws.send(JSON.stringify(message));
    },
    [GetChatRoomScrollDown]({ state }, data: { id: number; cursor?: string }) {
      if (!state.ws) return;

      const message: {
        code: number;
        data: { chatroom_id: number; cursor?: string };
      } = {
        code: CHAT_ROOM_CODE.POST_CHAT_ROOM_SCROLL_DOWN_MESSAGE,
        data: { chatroom_id: data.id, cursor: data.cursor }
      };

      state.ws.send(JSON.stringify(message));
    },
    [GetChatRoomMessages]({ state }, data: { id: number; cursor?: string }) {
      if (!state.ws) return;

      const message: {
        code: number;
        data: { chatroom_id: number; cursor?: string };
      } = {
        code: CHAT_ROOM_CODE.GET_CHAT_ROOM_SCROLL_UP_MESSAGE,
        data: { chatroom_id: data.id }
      };
      if (data.cursor) message.data.cursor = data.cursor;

      state.ws.send(JSON.stringify(message));
    },
    [PostNotifications]({ state }, cursor: string) {
      if (!state.ws) return;

      const message: {
        code: number;
        data: { cursor: string };
      } = {
        code: CHAT_ROOM_CODE.POST_NOTIFICATION,
        data: { cursor }
      };

      state.ws.send(JSON.stringify(message));
    },
    [PostChatRoomRead]({ state }, id: number) {
      if (!state.ws) return;

      const message: {
        code: number;
        data: { chatroom_id: number };
      } = {
        code: CHAT_ROOM_CODE.POST_CHAT_READ,
        data: { chatroom_id: id }
      };

      state.ws.send(JSON.stringify(message));
    },
    [PostChatRoomReadFirstTime]({ state }) {
      if (!state.ws) return;

      const message: {
        code: number;
      } = {
        code: CHAT_ROOM_CODE.POST_CHAT_READ_FIRST_TIME
      };

      state.ws.send(JSON.stringify(message));
    },
    [PostNotificationReadFirstTime]({ state }) {
      if (!state.ws) return;

      const message: {
        code: number;
      } = {
        code: CHAT_ROOM_CODE.POST_NOTIFICATION_READ_FIRST_TIME
      };

      state.ws.send(JSON.stringify(message));
    },
    [PostNotificationRead]({ state }, id?: number) {
      if (!state.ws) return;

      const message: {
        code: number;
        data?: { notification_id: number };
      } = {
        code: CHAT_ROOM_CODE.POST_NOTIFICATION_READ
      };
      if (id) {
        message.data = { notification_id: id };
      }

      state.ws.send(JSON.stringify(message));
    },
    [PostNotificationReadByProject]({ state }, id?: number) {
      if (!state.ws) {
        state.catch = [
          ...state.catch,
          { type: "notification_read_by_project", value: id }
        ];
        return;
      }

      const message: {
        code: number;
        data?: { project_id: number };
      } = {
        code: CHAT_ROOM_CODE.POST_NOTIFICATION_READ_BY_PROJECT
      };
      if (id) {
        message.data = { project_id: id };
      }

      state.ws.send(JSON.stringify(message));
    },
    [PostChatRoomTitle](
      { state },
      data: { chatroom_id: number; title: string }
    ) {
      if (!state.ws) return;

      const message: {
        code: number;
        data?: { chatroom_id: number; title: string };
      } = {
        code: CHAT_ROOM_CODE.POST_CHAT_ROOM_TITLE,
        data
      };

      state.ws.send(JSON.stringify(message));
    },
    [GetChatRoomSearchResults](
      { state },
      data: { chatroom_id: number; message: string }
    ) {
      if (!state.ws) return;

      const message: {
        code: number;
        data?: { chatroom_id: number; message: string };
      } = {
        code: CHAT_ROOM_CODE.POST_CHAT_ROOM_SEARCH_RESULTS,
        data
      };

      state.ws.send(JSON.stringify(message));
    },
    [GetChatRoomSearchMessages](
      { state },
      data: { chatroom_id: number; cursor: string }
    ) {
      if (!state.ws) return;

      const message: {
        code: number;
        data?: { chatroom_id: number; cursor: string };
      } = {
        code: CHAT_ROOM_CODE.POST_CHAT_ROOM_SEARCH_MESSAGE,
        data
      };

      state.ws.send(JSON.stringify(message));
    },
  }
};
