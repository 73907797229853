export enum HEADER_COLLAPSE_TYPE {
  NOTIFICATION,
  USER,
  CHAT_ROOM,
  CART,
}

export enum HEADER_NAVIGATIONS {
  HOME,
  FABRICS,
  DESIGN_AI,
  DESIGN_SIZE,
  STORY_NEWS,
  STORY_BRAND,
  STORY_CYCLE,
}
