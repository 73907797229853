import request from "@/apis/http";
import { AxiosPromise } from "axios";
import {
  CompaniesParams,
  CompanyCardResponse,
  CompanyResponse,
  CompanyLogoResponse, CompanyCreateParams
} from "@/typings/company";
import { MemberTeam } from "@/typings/member";

export function getCompanies(
  data?: CompaniesParams
): AxiosPromise<CompanyCardResponse[]> {
  return request({
    url: "/companies/",
    method: "GET",
    params: data
  });
}

export function getCompany(id: number): AxiosPromise<CompanyResponse> {
  return request({
    url: `/company/${id}/`,
    method: "GET"
  });
}

export function getCompanyTeams(id?: number): AxiosPromise<MemberTeam[]> {
  const url = id ? `/company/${id}/teams/` : `/company/teams/`;
  return request({
    url,
    method: "GET"
  });
}

export function postCompanyLogo(file: File): AxiosPromise<CompanyLogoResponse> {
  const form = new FormData();
  form.append("file", file);
  return request({
    url: '/company/medias/',
    method: "POST",
    data: form
  });
}

export function postCompanyCreate(data: CompanyCreateParams): AxiosPromise<{}> {
  const form = new FormData();
  form.append("name", data.name);
  form.append("company_type", data.company_type);
  if (data.logo > 0) form.append("logo", `${data.logo}`);
  return request({
    url: '/companies/',
    method: "POST",
    data: form
  });
}

export function putCompanyLogo(id: number, logo: number) {
  const form = new FormData();
  form.append("logo", `${logo}`);
  return request({
    url: `/company/${id}/`,
    method: "PUT",
    data: form
  });
}
