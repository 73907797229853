import request from "@/apis/http";
import requestEco from "@/apis/httpEco";
import { AxiosPromise } from "axios";
import {
  FabricDetailResponse,
  FabricFiltersResponse,
  FabricsParams,
  FabricsResponse,
  FabricRelatesResponse,
  StatusChangeParams,
  FabricRelatedCollectionResponse,
  FabricResult,
  PostUploadFabricParams,
  FabricType,
  SimilarFabricsResponse,
  FabricComment,
  FamilyFabric,
  EcoFabricsResponse,
  EcoFabricDetailResponse
} from "@/typings/fabric";
import { Message } from "@/typings/common";
import { CascaderOptionItem, OptionItemResponse } from "@/typings/asideFilter";
import { formatRealCompositionJson2Params } from "@/utils/fabric";

export function getFabrics(data: FabricsParams): AxiosPromise<FabricsResponse> {
  return request({
    url: "/fabrics/",
    method: "GET",
    params: data
  });
}

export function getFabricsWithImage(
  params: FabricsParams
): AxiosPromise<FabricsResponse> {
  const data = new FormData();
  if (params.name) data.append("name", params.name);
  if (params.real_composition)
    data.append("real_composition", params.real_composition);
  if (params.finished_specification)
    data.append("finished_specification", params.finished_specification);
  if (params.function) data.append("function", params.function);
  if (params.pattern) data.append("pattern", params.pattern);
  if (params.application) data.append("application", params.application);
  if (params.aesthetic) data.append("aesthetic", params.aesthetic);
  if (params.q) data.append("q", params.q);
  if (params.page) data.append("page", params.page.toString());
  if (params.status) data.append("status", params.status);
  if (params.to_project)
    data.append("to_project", params.to_project.toString());
  if (params.to_collection)
    data.append("to_collection", params.to_collection.toString());
  if (params.ordering) data.append("ordering", params.ordering);
  if (params.image) data.append("image", params.image);
  if (params.has_image) data.append("has_image", params.has_image.toString());

  return request({
    url: "/fabric/upload_image/similar_fabrics/",
    method: "POST",
    data
  });
}

export function getFabric(payload: {
  id: string | number;
  token: string | null;
}): AxiosPromise<FabricDetailResponse> {
  const params: { sharing_token?: string } = {};
  if (payload.token) {
    params.sharing_token = payload.token;
  }
  return request({
    url: `/fabric/${payload.id}/`,
    method: "GET",
    params
  });
}

export function getFabricFilters(
  data: FabricsParams
): AxiosPromise<FabricFiltersResponse> {
  return request({
    url: "/filter/fabric/",
    method: "GET",
    params: data
  });
}

export function postFabricStatus({ id, status }: StatusChangeParams) {
  return request({
    url: `/fabric/${id}/status/`,
    method: "PUT",
    data: { status }
  });
}

export function getFabricRelatedProject(
  id: string
): AxiosPromise<FabricRelatesResponse> {
  return request({
    url: `/fabric/${id}/related_project/`,
    method: "GET"
  });
}

export function getFabricRelatedCollections(
  id: string
): AxiosPromise<FabricRelatedCollectionResponse[]> {
  return request({
    url: `/fabric/${id}/related_collection/`,
    method: "GET"
  });
}

export function postAddFabricToFavorite(id: string): AxiosPromise<Message> {
  return request({
    url: `/fabric/${id}/add_to_favorite/`,
    method: "PUT"
  });
}

export function getFabricAnalyzeRecentView(): AxiosPromise<FabricResult> {
  return request({
    url: `/analyze_logs/fabrics/recent_view`,
    method: "GET"
  });
}

export function getFabricAnalyzeCompanyRank() {
  return request({
    url: `/analyze_logs/fabrics/company_rank`,
    method: "GET"
  });
}

export function getFabricAnalyzeObjectRank() {
  return request({
    url: `/analyze_logs/fabrics/object_rank`,
    method: "GET"
  });
}

export function getFabricCompositionList() {
  return request({
    url: `/fabrics/real_composition/`,
    method: "GET"
  });
}

export function getFabricNameList() {
  return request({
    url: `/fabrics/names/`,
    method: "GET"
  });
}

export function checkArtNo(params: { art_no?: string; sap_art_no?: string }) {
  return request({
    url: `/fabrics/art_no_check/`,
    method: "GET",
    params
  });
}

export function getFabricShadow(): AxiosPromise<FabricDetailResponse> {
  return request({
    url: `/fabric_shadow/`,
    method: "GET"
  });
}

export function postFabricShadow(params: any) {
  const data = new FormData();
  if (params.art_no) data.append("art_no", params.art_no);
  if (params.fabric_works_skus)
    data.append("fabric_works_skus", params.fabric_works_skus);
  if (params.main_photo) data.append("main_photo", params.main_photo);
  if (params.sap_art_no) data.append("sap_art_no", params.sap_art_no);
  if (params.name) data.append("name", params.name);
  if (params.width) data.append("width", params.width);
  if (params.weight) data.append("weight", params.weight);
  if (params.real_composition)
    data.append("real_composition", params.real_composition);
  if (params.real_composition_json)
    data.append(
      "real_composition_json",
      JSON.stringify(
        formatRealCompositionJson2Params(params.real_composition_json)
      )
    );
  if (params.status) data.append("status", params.status);
  // ****** 統一使用 limited_brands，但選項的屬性還是有兩個不同的來源 ******
  if (params.exclusive_for)
    data.append("limited_brands", JSON.stringify(params.exclusive_for));
  if (params.limited_brands && params.limited_brands.length) {
    for (let i = 0; i < params.limited_brands.length; i++) {
      data.append("limited_brands", params.limited_brands[i]);
    }
  }
  // ******************************************************************
  if (params.tg3d_fabric_id)
    data.append("tg3d_fabric_id", params.tg3d_fabric_id);
  if (params.photos && params.photos.length) {
    for (let i = 0; i < params.photos.length; i++) {
      data.append("photos", params.photos[i]);
    }
  }
  if (params.photos_with_id && params.photos_with_id.length) {
    for (let i = 0; i < params.photos_with_id.length; i++) {
      data.append("photos_with_id", JSON.stringify(params.photos_with_id[i]));
    }
  }
  if (params.video_urls && params.video_urls.length) {
    for (let i = 0; i < params.video_urls.length; i++) {
      data.append("video_urls", params.video_urls[i]);
    }
  }
  if (params.tags && params.tags.length) {
    for (let i = 0; i < params.tags.length; i++) {
      data.append("tags", JSON.stringify(params.tags[i]));
    }
  }
  if (params.families.length)
    data.append("families", JSON.stringify(params.families));
  data.append("mass_order", JSON.stringify(params.mass_order));
  data.append("approved", JSON.stringify(params.approved));

  return request({
    url: `/fabric_shadow/`,
    method: "PUT",
    data
  });
}

export function getFabricTagsType(): AxiosPromise<CascaderOptionItem[]> {
  return request({
    url: `/tags/tags_tree/fabric/`,
    method: "GET"
  });
}

export function getFabricTags(tagTypeId: number): AxiosPromise<FabricType[]> {
  return request({
    url: `/tags/${tagTypeId}/children/`,
    method: "GET"
  });
}

export function postUploadFabric(
  params: PostUploadFabricParams
): AxiosPromise<FabricDetailResponse> {
  const data = new FormData();
  data.append("fabric_works_skus", params.fabric_works_skus);
  if (params.main_photo) data.append("main_photo", params.main_photo);
  if (params.sap_art_no) data.append("sap_art_no", params.sap_art_no);
  if (params.art_no) data.append("art_no", params.art_no);
  if (params.name) data.append("name", params.name);
  if (params.width) data.append("width", `${params.width}`);
  if (params.weight) data.append("weight", `${params.weight}`);
  if (params.real_composition)
    data.append("real_composition", JSON.stringify(params.real_composition));
  if (params.real_composition_json)
    data.append(
      "real_composition_json",
      JSON.stringify(
        formatRealCompositionJson2Params(params.real_composition_json)
      )
    );
  if (params.status) data.append("status", params.status);
  // ****** 統一使用 limited_brands，但選項的屬性還是有兩個不同的來源 ******
  if (params.exclusive_for)
    data.append("limited_brands", JSON.stringify(params.exclusive_for));
  if (params.limited_brands && params.limited_brands.length) {
    for (let i = 0; i < params.limited_brands.length; i++) {
      data.append("limited_brands", params.limited_brands[i]);
    }
  }
  // ******************************************************************
  if (params.tg3d_fabric_id)
    data.append("tg3d_fabric_id", params.tg3d_fabric_id);
  if (params.photos && params.photos.length) {
    for (let i = 0; i < params.photos.length; i++) {
      data.append("photos", params.photos[i]);
    }
  }
  if (params.photos_with_id && params.photos_with_id.length) {
    for (let i = 0; i < params.photos_with_id.length; i++) {
      data.append("photos_with_id", JSON.stringify(params.photos_with_id[i]));
    }
  }
  if (params.video_urls && params.video_urls.length) {
    for (let i = 0; i < params.video_urls.length; i++) {
      data.append("video_urls", params.video_urls[i]);
    }
  }
  if (params.tags && params.tags.length) {
    for (let i = 0; i < params.tags.length; i++) {
      data.append("tags", JSON.stringify(params.tags[i]));
    }
  }
  if (params.families.length) {
    data.append("families", JSON.stringify(params.families));
  }
  data.append("mass_order", JSON.stringify(params.mass_order));
  data.append("approved", JSON.stringify(params.approved));
  return request({
    url: `/fabrics/`,
    method: "POST",
    data
  });
}

export function postDeleteFabric(id: number) {
  return request({
    url: `/fabric/${id}/`,
    method: "DELETE"
  });
}

export function postUpdateFabric(id: number, params: PostUploadFabricParams) {
  const data = new FormData();
  data.append("fabric_works_skus", params.fabric_works_skus);
  if (params.main_photo) data.append("main_photo", params.main_photo);
  if (params.art_no) data.append("art_no", params.art_no);
  if (params.sap_art_no) data.append("sap_art_no", params.sap_art_no);
  if (params.name) data.append("name", params.name);
  if (params.width) data.append("width", `${params.width}`);
  if (params.weight) data.append("weight", `${params.weight}`);
  if (params.real_composition)
    data.append("real_composition", JSON.stringify(params.real_composition));
  if (params.real_composition_json)
    data.append(
      "real_composition_json",
      JSON.stringify(
        formatRealCompositionJson2Params(params.real_composition_json)
      )
    );
  if (params.status) data.append("status", params.status);
  // ****** 統一使用 limited_brands，但選項的屬性還是有兩個不同的來源 ******
  if (params.exclusive_for)
    data.append("limited_brands", JSON.stringify(params.exclusive_for));
  if (params.limited_brands && params.limited_brands.length) {
    for (let i = 0; i < params.limited_brands.length; i++) {
      data.append("limited_brands", params.limited_brands[i]);
    }
  }
  // ******************************************************************
  if (params.tg3d_fabric_id)
    data.append("tg3d_fabric_id", params.tg3d_fabric_id);
  if (params.photos && params.photos.length) {
    for (let i = 0; i < params.photos.length; i++) {
      data.append("photos", params.photos[i]);
    }
  }
  if (params.photos_with_id && params.photos_with_id.length) {
    for (let i = 0; i < params.photos_with_id.length; i++) {
      data.append("photos_with_id", JSON.stringify(params.photos_with_id[i]));
    }
  }
  if (params.video_urls && params.video_urls.length) {
    for (let i = 0; i < params.video_urls.length; i++) {
      data.append("video_urls", params.video_urls[i]);
    }
  }
  if (params.tags && params.tags.length) {
    for (let i = 0; i < params.tags.length; i++) {
      data.append("tags", JSON.stringify(params.tags[i]));
    }
  }
  data.append("families", JSON.stringify(params.families));
  data.append("mass_order", JSON.stringify(params.mass_order));
  data.append("approved", JSON.stringify(params.approved));
  return request({
    url: `/fabric/${id}/`,
    method: "PUT",
    data
  });
}

export function putFabricVisitWithoutLogin(id: number, isAvailable: boolean) {
  const data = new FormData();
  data.append("is_shared", JSON.stringify(isAvailable));
  return request({
    url: `/fabric/${id}/`,
    method: "PUT",
    data
  });
}

export function getSimilarFabrics(
  fabricId: number,
  params?: { tag_type: string }
): AxiosPromise<SimilarFabricsResponse> {
  return request({
    url: `/fabric/${fabricId}/similar_fabrics/`,
    method: "GET",
    params
  });
}

export function getFabricThemes(): AxiosPromise<OptionItemResponse[]> {
  return request({
    url: `/fabric/family_themes/`,
    method: "GET"
  });
}

export function getPriorFabrics(
  theme: string,
  priors: string
): AxiosPromise<OptionItemResponse[]> {
  const params: any = {};
  if (priors) params.existed_prior_fabrics = priors;
  return request({
    url: `/fabric/${theme}/prior_fabrics/`,
    method: "GET",
    params
  });
}

export function getFabricComments(
  fabricId: number
): AxiosPromise<FabricComment[]> {
  return request({
    url: `/comments/fabric/${fabricId}/`,
    method: "GET"
  });
}

export function postFabricComment(
  fabricId: number,
  message: string
): AxiosPromise<{ message: string }> {
  return request({
    url: `/comments/fabric/${fabricId}/`,
    method: "POST",
    data: { message }
  });
}

export function putFabricComment(
  fabricId: number,
  message: string
): AxiosPromise<{ message: string }> {
  return request({
    url: `/comment/${fabricId}/`,
    method: "PUT",
    data: { message }
  });
}

export function deleteFabricComment(commentId: number) {
  return request({
    url: `/comment/${commentId}/`,
    method: "DELETE"
  });
}

export function getFamilyFabricThemes(
  fabricId: number
): AxiosPromise<OptionItemResponse[]> {
  return request({
    url: `/fabric/${fabricId}/family_themes/`,
    method: "GET"
  });
}

export function getFamilyFabricData(
  id: number,
  tab: string,
  theme: string
): AxiosPromise<FamilyFabric[]> {
  return request({
    url: `/fabric/${id}/${theme}/${tab}/family_tree/`,
    method: "GET"
  });
}

export function getDownloadExcel() {
  return request({
    url: `/fabric/multiupload/sample_excel/download/`,
    method: "GET",
    responseType: "blob"
  });
}

export function postFabricBatchUpload(file: File) {
  const form = new FormData();
  form.append("file", file);
  return request({
    url: `/fabric/multiupload/`,
    method: "POST",
    data: form
  });
}

export function getFabricBatchUploadTime(size: number) {
  const params = { file_size: size };
  return request({
    url: `/fabric/multiupload/`,
    method: "GET",
    params
  });
}

// ecofabrics
export function getEcoFabrics(
  data: FabricsParams
): AxiosPromise<EcoFabricsResponse> {
  return requestEco({
    url: "/fabricvariants/",
    method: "GET",
    params: data
  });
}

// 專門給以圖搜圖使用
// 會回傳 cache_key
export function postEcoFabrics(data: {
  image: File;
}): AxiosPromise<{ cache_key: string }> {
  const formData = new FormData();
  formData.append("image", data.image);
  return requestEco({
    url: "/fabricvariants/",
    method: "POST",
    data: formData
  });
}

export function getEcoFabric(payload: {
  id: string | number;
}): AxiosPromise<EcoFabricDetailResponse> {
  return requestEco({
    url: `/fabricvariant/${payload.id}/`,
    method: "GET",
    params: payload
  });
}

export function getEcoFabricRecommends(id: string): AxiosPromise {
  return request({
    url: `/fabricvariant/recommend/${id}`,
    method: "GET"
  });
}

export function getEcoFabricFilters(
  data: FabricsParams
): AxiosPromise<FabricFiltersResponse> {
  return requestEco({
    url: "/fabric-filter-options/",
    method: "GET",
    params: data
  });
}

export function postEcoFabricToFavorite(id: string): AxiosPromise {
  const data = new FormData();
  data.append("fabric_variant", id);
  return request({
    url: `/followed-fabric-variants`,
    method: "POST",
    data
  });
}

export function deleteEcoFabricToFavorite(id: string): AxiosPromise {
  return request({
    url: `/followed-fabric-variants/${id}/`,
    method: "DELETE"
  });
}
