import request from "@/apis/http";
import { AxiosPromise } from "axios";
import {
  CollectionsParams,
  CollectionDuplicateParams,
  CollectionFilterResponse,
  CollectionCardResponse,
  CollectionDetailResponse,
  CollectionUpdateFabricPriceParams,
  OptionParams,
  CollectionUpdateJoinedMemberParams,
  ChangeOrderParams,
  ChangeFolderNameParams
} from "@/typings/collection";
import { FabricRelatedCollectionResponse } from "@/typings/fabric";

export function getCollections(
  data: CollectionsParams
): AxiosPromise<CollectionCardResponse[]> {
  return request({
    url: "/collections/",
    method: "GET",
    params: data
  });
}

export function getOwnCollections(): AxiosPromise<
  FabricRelatedCollectionResponse[]
> {
  return request({
    url: "/collections/own/",
    method: "GET"
  });
}

export function getCollection(
  payload: {id: string | number, token?: string}
): AxiosPromise<CollectionDetailResponse> {
  const params: {sharing_token?: string} = {};
  if (payload.token) {
    params.sharing_token = payload.token
  }
  return request({
    url: `/collection/${payload.id}/`,
    method: "GET",
    params
  });
}

export function getCollectionGroups(payload: {id: string}): AxiosPromise<any> {
  return request({
    url: `/collection/${payload.id}/groups/`,
    method: "GET",
  });
}

export function getCollectionFilters(): AxiosPromise<CollectionFilterResponse> {
  return request({
    url: "/filter/collection/",
    method: "GET"
  });
}

export function postCollectionDuplicate(params: CollectionDuplicateParams) {
  return request({
    url: `/collection/duplicate/`,
    method: "POST",
    data: params
  });
}

export function putCollectionVisitWithoutLogin(id: number, isAvailable: boolean) {
  const data = new FormData();
  data.append("is_shared", JSON.stringify(isAvailable));
  return request({
    url: `/collection/${id}/`,
    method: "PUT",
    data
  });
}

export function postCreateCollection(params: any) {
  return request({
    url: `/collection/`,
    method: "POST",
    data: params
  });
}

export function postUpdateCollection(params: any, id: number) {
  return request({
    url: `/collection/${id}/`,
    method: "PUT",
    data: params
  });
}

export function postCollectionCreateFabric({
  collection_id,
  fabric_id,
  price
}: CollectionUpdateFabricPriceParams) {
  return request({
    url: `/collection/${collection_id}/fabric/${fabric_id}`,
    method: "POST",
    data: { price }
  });
}

export function deleteCollection(id: number) {
  return request({
    url: `/collection/${id}/`,
    method: 'DELETE'
  })
}

export function deleteCollectionFabric({
  collection_id,
  fabric_id
}: CollectionUpdateFabricPriceParams) {
  return request({
    url: `/collection/${collection_id}/fabric/${fabric_id}`,
    method: "DELETE"
  });
}

export function postCollectionUpdateFabricPrice({
  collection_id,
  fabric_id,
  price,
  price_unit
}: CollectionUpdateFabricPriceParams) {
  return request({
    url: `/collection/${collection_id}/fabric/${fabric_id}/price/`,
    method: "PUT",
    data: { price, price_unit }
  });
}

export function getAvailableMembers({ id, q }: OptionParams) {
  return request({
    url: `/collection/${id}/avalible_member/`,
    method: "GET",
    params: q
  });
}

export function getJoinedMembers(id: number) {
  return request({
    url: `/collection/${id}/joined_member/`,
    method: "GET"
  });
}

export function postUpdateJoinedMembers({
  collection_id,
  member_id
}: CollectionUpdateJoinedMemberParams) {
  return request({
    url: `/collection/${collection_id}/joined_member/${member_id}/`,
    method: "PUT"
  });
}

export function getCollectionsAnalyzeRecentView() {
  return request({
    url: `/analyze_logs/collections/recent_view`,
    method: "GET"
  });
}

export function getCollectionAnalyzeRecentView(id: number) {
  return request({
    url: `/analyze_logs/collection/${id}/recent_view`,
    method: "GET"
  });
}

export function getCollectionsAnalyzeCompanyRank() {
  return request({
    url: `/analyze_logs/collections/company_rank`,
    method: "GET"
  });
}

export function getCollectionAnalyzeCompanyRank(id: number) {
  return request({
    url: `/analyze_logs/collection/${id}/company_rank`,
    method: "GET"
  });
}

export function getCollectionsAnalyzeObjectRank() {
  return request({
    url: `/analyze_logs/collections/object_rank`,
    method: "GET"
  });
}

export function getCollectionAnalyzeObjectRank(id: number) {
  return request({
    url: `/analyze_logs/collection/${id}/object_rank`,
    method: "GET"
  });
}

export function putCollectionsChangeOrder({ id, orders, groups }: ChangeOrderParams) {
  return request({
    url: `/collection/${id}/fabrics/`,
    method: "PUT",
    data: { orders, groups }
  });
}

export function putCollectionsChangeFolderName({ collection, group, name }: ChangeFolderNameParams) {
  return request({
    url: `/collection/${collection}/group/${group}/`,
    method: "PUT",
    data: { name }
  });
}

export function getDownloadExcel(collectionId: number) {
  return request({
    url: `/collection/${collectionId}/excel/download/`,
    method: "GET",
    responseType: 'blob'
  })
}

export function getDownloadPpt(collectionId: number, templateId: number) {
  return request({
    url: `/collection/${collectionId}/ppt/${templateId}/download/`,
    method: "GET",
    responseType: 'blob'
  })
}

export function postCreateCollectionFolder(collectionId: string, name: string) {
  const form = new FormData();
  form.append('name', name);
  return request({
    url: `/collection/${collectionId}/groups/`,
    method: "POST",
    data: form
  })
}

export function deleteCollectionFolder(collectionId: number, folderId: number) {
  return request({
    url: `/collection/${collectionId}/group/${folderId}/`,
    method: 'DELETE'
  })
}
