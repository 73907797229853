/* eslint-disable */
import "@shopify/buy-button-js/dist/buybutton.min";
import "@shopify/buy-button-js/dist/buybutton.css";

const lineItemTemplates = {
  image:
    '<div class="{{data.classes.lineItem.image}}" style="background-image: url({{data.lineItemImage}})" data-element="lineItem.image"></div>',
  title:
    '<span aris-data="{{data.metafields.custom.moq}}" class="{{data.classes.lineItem.itemTitle}}" data-element="lineItem.itemTitle">{{data.title}}</span>',
  price:
    `<span class="{{data.classes.lineItem.price}}" data-element="lineItem.price">{{data.formattedPrice}}</span>`,
  quantity: `<div class="{{data.classes.lineItem.quantity}}" style="display: flex; align-items: flex-end" data-element="lineItem.quantity">
               <button class="{{data.classes.lineItem.quantityButton}} {{data.classes.lineItem.quantityDecrement}}" type="button" data-line-item-id="{{data.id}}" data-element="lineItem.quantityDecrement">
                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M4 7h8v2H4z"/></svg><span class="visuallyhidden">Decrement</span>
               </button>
               <input class="{{data.classes.lineItem.quantityInput}}" type="number" min="0" aria-label="Quantity" data-line-item-id="{{data.id}}" value="{{data.quantity}}" data-element="lineItem.quantityInput">
               <button class="{{data.classes.lineItem.quantityButton}} {{data.classes.lineItem.quantityIncrement}}" type="button" data-line-item-id="{{data.id}}" data-element="lineItem.quantityIncrement">
                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M12 7H9V4H7v3H4v2h3v3h2V9h3z"/></svg><span class="visuallyhidden">Increment</span>
               </button>
               <span style="margin-left: 8px;">yds</span>
             </div>`
};

export default {
  install: (app, options) => {
    const client = ShopifyBuy.buildClient({
      domain: process.env.VUE_APP_SHOPIFY_DOMAIN,
      storefrontAccessToken: process.env.VUE_APP_SHOPIFY_TOKEN
    });

    // Init
    app.config.globalProperties.$shopify = ShopifyBuy.UI.init(client);

    // Product
    app.config.globalProperties.$shopify.createProduct = ({ id }) => {
      app.config.globalProperties.$shopify.createComponent("product", {
        id,
        node: document.getElementById(`product-component-${id}`),
        moneyFormat: "%24%7B%7Bamount%7D%7D",
        options: {
          product: {
            iframe: false,
            isButton: true,
            styles: {
              product: {
                "margin": '0px'
              },
              button: {
                "border-radius": "4px",
                'background-color': '#20bfa9',
                ':hover': {
                  'background-color': '#4acab9'
                }
              }
            },
            contents: {
              img: false,
              title: false,
              price: false,
            },
            text: {
              button: "加入購物車"
            },
            events: {
              addVariantToCart(product) {
                const quantity = +product.node.dataset.quantity;
                if (quantity > 0) {
                  product.selectedQuantity = quantity;
                  // console.log(product.node.dataset.minqty);
                  // if (product.cart.model.lineItemCount < minQty) {
                  //   product.updateQuantity(function() {
                  //     return minQty - product.cart.model.lineItemCount;
                  //   });
                }
              }
            }
          },
          lineItem: {
            templates: lineItemTemplates,
            contents: {
              price: false
            },
          },
          cart: {
            iframe: false,
            popup: false,
            startOpen: false,
            styles: {
              button: {
                "border-radius": "4px",
                'background': '#20bfa9',
                ':hover': {
                  'background': '#4acab9'
                }
              }
            },
            text: {
              title: "購物車",
              total: "合計",
              button: "結帳",
              notice: "",
              empty: "您的購物車是空的"
            },
            // events: {
            //   updateItemQuantity(cart) {
            // console.log(cart);
            // console.log(b);
            // console.log(c);
            // const products = app.config.globalProperties.$shopify.components
            // console.log('products', products);
            // console.log(products[0].node.dataset)
            // console.log(cart);
            // console.log(cart.node);
            // const moq = +cart.node.dataset.moq;
            // console.log('moq', moq);
            //   }
            // }
          },
        }
      });
    }

    // TestFabric
    app.config.globalProperties.$shopify.createTestFabric = ({ id }) => {
      app.config.globalProperties.$shopify.createComponent("product", {
        id,
        node: document.getElementById(`test-fabric-component-${id}`),
        moneyFormat: "%24%7B%7Bamount%7D%7D",
        options: {
          product: {
            iframe: false,
            isButton: true,
            styles: {
              product: {
                "margin": '0px'
              },
              button: {
                "border-radius": "4px",
                'background-color': '#20bfa9',
                ':hover': {
                  'background-color': '#4acab9'
                }
              }
            },
            contents: {
              img: false,
              title: false,
              price: false,
            },
            text: {
              button: "索取樣布"
            },
            events: {
              addVariantToCart(product) {
                const quantity = +product.node.dataset.quantity;
                if (quantity > 0) {
                  product.selectedQuantity = quantity;
                  // console.log(product.node.dataset.minqty);
                  // if (product.cart.model.lineItemCount < minQty) {
                  //   product.updateQuantity(function() {
                  //     return minQty - product.cart.model.lineItemCount;
                  //   });
                }
              }
            },
            lineItem: {
              templates: lineItemTemplates,
              contents: {
                price: false
              },
            },
            cart: {
              iframe: false,
              popup: false,
              startOpen: false,
              styles: {
                button: {
                  "border-radius": "4px",
                  'background': '#20bfa9',
                  ':hover': {
                    'background': '#4acab9'
                  }
                }
              },
              text: {
                title: "購物車",
                total: "合計",
                button: "結帳",
                notice: "",
                empty: "您的購物車是空的"
              },
              // events: {
              //   updateItemQuantity(cart) {
              // console.log(cart);
              // console.log(b);
              // console.log(c);
              // const products = app.config.globalProperties.$shopify.components
              // console.log('products', products);
              // console.log(products[0].node.dataset)
              // console.log(cart);
              // console.log(cart.node);
              // const moq = +cart.node.dataset.moq;
              // console.log('moq', moq);
              //   }
              // }
            },
          }
        }
      });
    }
  }
}
