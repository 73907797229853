import Cookies from "js-cookie";
import store from "@/store";

const tokenKey = "token_key";
const registerTokenKey = "register_token_key";
const refreshTokenKey = "refresh_key";
const resetTokenKey = "reset_key";
const permissionKey = "permission_key";
const popupsRememberKey = "popups_remember_key";
const storage = window.localStorage;

export type Permission = {
  "-": boolean;
  brandlake_view: boolean;
  chatroom_add: boolean;
  chatroom_view: boolean;
  collection_add: boolean;
  collection_analysis_view: boolean;
  collection_delete: boolean;
  collection_download: boolean;
  collection_fabric_add: boolean;
  collection_order_update: boolean;
  collection_price_update: boolean;
  collection_selected_update: boolean;
  collection_update: boolean;
  collection_view: boolean;
  contact_us: boolean;
  company_update: boolean;
  company_view: boolean;
  edm_update: boolean;
  edm_view: boolean;
  fabric_add: boolean;
  fabric_analysis_view: boolean;
  fabric_family_view: boolean;
  fabric_file_download: boolean;
  fabric_related_collection_view: boolean;
  fabric_related_project_view: boolean;
  fabric_similar_view: boolean;
  fabric_status_update: boolean;
  fabric_update: boolean;
  fabric_view: boolean;
  fabric_work_view: boolean;
  member_colleague_add: boolean;
  member_invite_add: boolean;
  member_status_update: boolean;
  member_update: boolean;
  member_brand_add: boolean;
  message_board_view: boolean;
  project_add: boolean;
  project_discussion_add: boolean;
  project_members_update: boolean;
  project_stage_add: boolean;
  project_status_update: boolean;
  project_view: boolean;
  project_stage_update: boolean;
  review_view: boolean;
  supplier_update: boolean;
  suppliers_view: boolean;
};

export const findToken = (): string | null => {
  return storage.getItem(tokenKey);
};

export const setToken = (token: string): void => {
  storage.setItem(tokenKey, token);
  Cookies.set(tokenKey, token, {
    domain: ".ecofabrics.world", // 跨子域共享
    path: "/", // 全域有效
    secure: true, // 僅在 HTTPS 下傳輸
    sameSite: "None" // 允許跨域請求傳遞
  });
};

export const removeToken = (): void => {
  storage.removeItem(tokenKey);
  // 刪除 jwtToken Cookie
  Cookies.remove(tokenKey, {
    domain: ".ecofabrics.world", // 確保與設置時相同的域
    path: "/" // 確保與設置時相同的路徑
  });
};

export const findRegisterToken = (): string | null => {
  return storage.getItem(registerTokenKey);
};

export const findResetToken = (): string | null => {
  return storage.getItem(resetTokenKey);
};

export const setRegisterToken = (token: string): void => {
  storage.setItem(registerTokenKey, token);
};

export const removeRegisterToken = (): void => {
  storage.removeItem(registerTokenKey);
};

export const findRefreshToken = (): string | null => {
  return storage.getItem(refreshTokenKey);
};

export const setRefreshToken = (token: string): void => {
  storage.setItem(refreshTokenKey, token);
};

export const removeRefreshToken = (): void => {
  storage.removeItem(refreshTokenKey);
};

export const setResetToken = (token: string): void => {
  storage.setItem(resetTokenKey, token);
};

export const removeResetToken = (): void => {
  storage.removeItem(resetTokenKey);
};

export const findPermission = (): Permission | null => {
  const data = storage.getItem(permissionKey);
  return data && JSON.parse(data);
};

export const setPermission = (permission: Permission): void => {
  storage.setItem(permissionKey, JSON.stringify(permission));
};

export const removePermission = (): void => {
  storage.removeItem(permissionKey);
};

export const findPopupsRemember = (): string | null => {
  return storage.getItem(popupsRememberKey);
};

export const setPopupsRemember = (token: string): void => {
  storage.setItem(popupsRememberKey, token);
};

export const removePopupsRemember = (): void => {
  storage.removeItem(popupsRememberKey);
};
