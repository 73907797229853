import { Module } from "vuex";
import {
  Login,
  SetPermission,
  SetIsLogin,
  GetPermission,
  ChangePassword,
  Logout
} from "@/typings/store";
import {
  ChangePasswordParams,
  getPermission,
  LoginParams,
  putChangePassword,
  postLogin
} from "@/apis/auth";
import {
  setRefreshToken,
  setToken,
  setPermission,
  Permission,
  findPermission,
  findToken,
  removeToken,
  removeRefreshToken
} from "@/utils/tokenStorage";
import { ElNotification } from "element-plus";
import { getCurrentInstance } from "vue";

export type AuthState = {
  isLogin: boolean;
  permission: Permission;
};

const initPermission = (): Permission => ({
  "-": false,
  brandlake_view: false,
  chatroom_add: false,
  chatroom_view: false,
  collection_add: false,
  collection_analysis_view: false,
  collection_delete: false,
  collection_download: false,
  collection_fabric_add: false,
  collection_order_update: false,
  collection_price_update: false,
  collection_selected_update: false,
  collection_update: false,
  collection_view: true, // 訪客有權限看
  contact_us: true, // 訪客才會看到
  company_update: false,
  company_view: false,
  edm_update: false,
  edm_view: false,
  fabric_add: false,
  fabric_analysis_view: false,
  fabric_family_view: false,
  fabric_file_download: false,
  fabric_related_collection_view: false,
  fabric_related_project_view: false,
  fabric_similar_view: false,
  fabric_status_update: false,
  fabric_update: false,
  fabric_view: true, // 訪客有權限看
  fabric_work_view: false,
  member_colleague_add: false,
  member_invite_add: false,
  member_status_update: false,
  member_update: false,
  member_brand_add: false,
  message_board_view: false,
  project_add: false,
  project_discussion_add: false,
  project_members_update: false,
  project_stage_add: false,
  project_status_update: false,
  project_view: false,
  project_stage_update: false,
  review_view: false,
  supplier_update: false,
  suppliers_view: false
});

export const auth: Module<AuthState, any> = {
  state: {
    isLogin: !!findToken(),
    permission: findPermission() || initPermission()
  },
  mutations: {
    [SetIsLogin](state, data) {
      state.isLogin = data;
    },
    [SetPermission](state, data) {
      state.permission = data;
    }
  },
  actions: {
    [Login]: async ({ commit, dispatch }, payload: LoginParams) => {
      try {
        const { data } = await postLogin(payload);
        setToken(data.access);
        setRefreshToken(data.refresh);

        const cart = document.querySelector(".shopify-buy-frame--toggle");
        if (cart) {
          //@ts-ignore
          cart.style.display = "block";
        }

        commit(SetIsLogin, true);

        // const { data: permission } = await getPermission(data.access);
        // setPermission(permission);
        // commit(SetPermission, permission);
        return true;
      } catch (e) {
        return false;
      }
    },
    [Logout]: async ({ commit }) => {
      try {
        removeToken();
        removeRefreshToken();

        const cart = document.querySelector(".shopify-buy-frame--toggle");
        if (cart) {
          //@ts-ignore
          cart.style.display = "none";
        }

        commit(SetIsLogin, false);
        return true;
      } catch (e) {
        return false;
      }
    },
    [ChangePassword]: async (_, payload: ChangePasswordParams) => {
      try {
        await putChangePassword(payload);
        // setToken(data.access);
        // setRefreshToken(data.refresh);
        // commit(SetIsLogin, true);

        // const { data: permission } = await getPermission(data.access);
        // setPermission(permission);
        // commit(SetPermission, permission);
        return true;
      } catch (e) {
        ElNotification({
          type: "error",
          title: "Error",
          message: e.error
        });
        return false;
      }
    },
    [GetPermission]: async ({ commit }) => {
      const { data } = await getPermission();
      setPermission(data);
      commit(SetPermission, data);
    }
  }
};
