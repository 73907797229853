export enum HEADER_COLLAPSE_TYPE {
  NOTIFICATION,
  USER,
  CHAT_ROOM,
  CART
}

export enum FOOTER_ICONS {
  FACEBOOK,
  INSTAGRAM,
  LINE
}
