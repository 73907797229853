import { Module } from "vuex";
import * as _ from "ramda";
import {
  SetCollectionList,
  SetCollectionFilters,
  GetCollections,
  GetCollectionFilters,
  PostCollectionDuplicate,
  PostCreateCollection,
  PostCollectionUpdateFabricPrice,
  GetCollection,
  SetCollectionDetail,
  SetOwnCollections,
  GetOwnCollections,
  PostCollectionCreateFabric,
  DeleteCollectionFabric,
  PostUpdateCollection,
  GetAvailalbeMembers,
  GetJoinedMembers,
  PostUpdateJoinedMembers,
  SetAvailableMembers,
  SetJoinedMembers,
  GetCollectionsAnalyzeRecentView,
  GetCollectionsAnalyzeCompanyRank,
  GetCollectionsAnalyzeObjectRank,
  SetCollectionsAnalyzeRecentView,
  SetCollectionsAnalyzeCompanyRank,
  SetCollectionsAnalyzeObjectRank,
  GetCollectionAnalyzeCompanyRank,
  GetCollectionAnalyzeObjectRank,
  SetCollectionAnalyzeCompanyRank,
  SetCollectionAnalyzeObjectRank,
  GetCollectionAnalyzeRecentView,
  SetCollectionAnalyzeRecentView,
  GetCollectionGroups, SetCollectionGroups
} from "@/typings/store";
import {
  getCollectionFilters,
  getCollections,
  getCollection,
  postCollectionDuplicate,
  postCreateCollection,
  postUpdateCollection,
  postCollectionUpdateFabricPrice,
  getOwnCollections,
  postCollectionCreateFabric,
  deleteCollectionFabric,
  getAvailableMembers,
  getJoinedMembers,
  postUpdateJoinedMembers,
  getCollectionsAnalyzeRecentView,
  getCollectionsAnalyzeCompanyRank,
  getCollectionsAnalyzeObjectRank,
  getCollectionAnalyzeCompanyRank,
  getCollectionAnalyzeObjectRank,
  getCollectionAnalyzeRecentView, getCollectionGroups
} from "@/apis/collection";
import {
  CollectionCardResponse,
  CollectionDetailResponse,
  CollectionDuplicateParams,
  CollectionFilterResponse,
  JoinedMember,
  AvailableMember, CollectionGroup
} from "@/typings/collection";
import {
  FabricRelatedCollectionResponse,
  FabricResult
} from "@/typings/fabric";

type CollectionState = {
  list: CollectionCardResponse[];
  filters: CollectionFilterResponse;
  groups: CollectionGroup[] | null,
  detail: CollectionDetailResponse | null;
  ownList: FabricRelatedCollectionResponse[];
  availableMembers: AvailableMember[];
  joinedMembers: JoinedMember[];
  analysis: {
    collections: {
      recentView: FabricRelatedCollectionResponse[];
      companyRank: [];
      objectRank: FabricRelatedCollectionResponse[];
    };
    collection: {
      recentView: FabricResult[];
      companyRank: [];
      objectRank: FabricResult[];
    };
  };
};

const initState = (): CollectionState => {
  return {
    list: [],
    filters: {
      seasons: [],
      sports: [],
      years: []
    },
    groups: null,
    detail: null,
    ownList: [],
    availableMembers: [],
    joinedMembers: [],
    analysis: {
      collections: {
        recentView: [],
        companyRank: [],
        objectRank: []
      },
      collection: {
        recentView: [],
        companyRank: [],
        objectRank: []
      }
    }
  };
};

export const collection: Module<CollectionState, any> = {
  state: initState(),
  mutations: {
    [SetCollectionList](state, data) {
      state.list = data;
    },
    [SetOwnCollections](state, data) {
      state.ownList = data;
    },
    [SetCollectionGroups](state, data) {
      state.groups = data
    },
    [SetCollectionFilters](state, data) {
      state.filters = data;
    },
    [SetCollectionDetail](state, data) {
      state.detail = data;
    },
    [SetAvailableMembers](state, data) {
      state.availableMembers = data;
    },
    [SetJoinedMembers](state, data) {
      state.joinedMembers = data;
    },
    [SetCollectionsAnalyzeRecentView](state, data) {
      state.analysis.collections.recentView = _.splitAt<
        FabricRelatedCollectionResponse
      >(
        5,
        data.result.map((it: FabricRelatedCollectionResponse) => ({
          ...it,
          fabric_images: it.fabrics.map(fabric => fabric.image_url)
        }))
      )[0];
    },
    [SetCollectionsAnalyzeCompanyRank](state, data) {
      state.analysis.collections.companyRank = data.result;
    },
    [SetCollectionsAnalyzeObjectRank](state, data) {
      state.analysis.collections.objectRank = data.result.map(
        (it: FabricRelatedCollectionResponse) => ({
          ...it,
          fabric_images: it.fabrics.map(fabric => fabric.image_url)
        })
      );
    },
    [SetCollectionAnalyzeRecentView](state, data) {
      state.analysis.collection.recentView = _.splitAt<FabricResult>(
        5,
        data.result
      )[0];
    },
    [SetCollectionAnalyzeCompanyRank](state, data) {
      state.analysis.collection.companyRank = data.result;
    },
    [SetCollectionAnalyzeObjectRank](state, data) {
      state.analysis.collection.objectRank = data.result;
    }
  },
  actions: {
    [GetCollections]: async ({ commit }, payload) => {
      const { data } = await getCollections(payload);
      commit(SetCollectionList, data);
    },
    [GetOwnCollections]: async ({ commit }) => {
      const { data } = await getOwnCollections();
      commit(SetOwnCollections, data);
    },
    [GetCollection]: async ({ commit }, payload) => {
      const { data } = await getCollection(payload);
      commit(SetCollectionDetail, data);
    },
    [GetCollectionGroups]: async ({commit}, payload) => {
      const { data } = await getCollectionGroups(payload);
      commit(SetCollectionGroups, data);
    },
    [GetCollectionFilters]: async ({ commit }) => {
      const { data } = await getCollectionFilters();
      commit(SetCollectionFilters, data);
    },
    [PostCollectionDuplicate]: async (
      { commit },
      payload: CollectionDuplicateParams
    ) => {
      const { data } = await postCollectionDuplicate(payload);
      return data;
    },
    [PostCreateCollection]: async ({ commit }, payload) => {
      const { data } = await postCreateCollection(payload);
      return data;
    },
    [PostUpdateCollection]: async (_, payload) => {
      const { data } = await postUpdateCollection(payload.params, payload.id);
      return data;
    },
    [PostCollectionCreateFabric]: async (_, payload) => {
      const { data } = await postCollectionCreateFabric(payload);
      return data;
    },
    [DeleteCollectionFabric]: async (_, payload) => {
      const { data } = await deleteCollectionFabric(payload);
      return data;
    },
    [PostCollectionUpdateFabricPrice]: async (_, payload) => {
      const { data } = await postCollectionUpdateFabricPrice(payload);
      return data;
    },
    [GetAvailalbeMembers]: async ({ commit }, payload) => {
      const { data } = await getAvailableMembers(payload);
      commit(SetAvailableMembers, data);
    },
    [GetJoinedMembers]: async ({ commit }, payload) => {
      const { data } = await getJoinedMembers(payload);
      commit(SetJoinedMembers, data);
    },
    [PostUpdateJoinedMembers]: async (_, payload) => {
      const { data } = await postUpdateJoinedMembers(payload);
      return data;
    },
    [GetCollectionsAnalyzeRecentView]: async ({ commit }) => {
      const { data } = await getCollectionsAnalyzeRecentView();
      commit(SetCollectionsAnalyzeRecentView, data);
    },
    [GetCollectionsAnalyzeCompanyRank]: async ({ commit }) => {
      const { data } = await getCollectionsAnalyzeCompanyRank();
      commit(SetCollectionsAnalyzeCompanyRank, data);
    },
    [GetCollectionsAnalyzeObjectRank]: async ({ commit }) => {
      const { data } = await getCollectionsAnalyzeObjectRank();
      commit(SetCollectionsAnalyzeObjectRank, data);
    },
    [GetCollectionAnalyzeRecentView]: async ({ commit }, id: number) => {
      const { data } = await getCollectionAnalyzeRecentView(id);
      commit(SetCollectionAnalyzeRecentView, data);
    },
    [GetCollectionAnalyzeCompanyRank]: async ({ commit }, id: number) => {
      const { data } = await getCollectionAnalyzeCompanyRank(id);
      commit(SetCollectionAnalyzeCompanyRank, data);
    },
    [GetCollectionAnalyzeObjectRank]: async ({ commit }, id: number) => {
      const { data } = await getCollectionAnalyzeObjectRank(id);
      commit(SetCollectionAnalyzeObjectRank, data);
    }
  }
};
