import { Module } from "vuex";
import { EdmCardResponse, EdmParams, SendEdmParams } from "@/typings/edm";
import {
  GetEdms,
  GetEdm,
  SetEdms,
  SetEdm,
  PostCreateEdm,
  PostUpdateEdm,
  PostDuplicateEdm,
  PostSendEdm
} from "@/typings/store";
import { getEdms, getEdm, postCreateEdm, postUpdateEdm, sendEdm, postDuplicateEdm } from "@/apis/edm";

type EdmState = {
  list: EdmCardResponse[];
  detail: {};
};

const initState = (): EdmState => ({
  list: [],
  detail: {}
});

export const edm: Module<EdmState, any> = {
  state: initState(),
  mutations: {
    [SetEdms](state, data) {
      state.list = data;
    },
    [SetEdm](state, data) {
      state.detail = data;
    }
  },
  actions: {
    [GetEdms]: async ({ commit }) => {
      const { data } = await getEdms();
      commit(SetEdms, data);
    },
    [GetEdm]: async ({ commit }, id: number) => {
      const { data } = await getEdm(id);
      commit(SetEdm, data);
    },
    [PostCreateEdm]: async (_, payload: EdmParams) => {
      const { data } = await postCreateEdm(payload);
      return data;
    },
    [PostUpdateEdm]: async (_, payload) => {
      const { data } = await postUpdateEdm(payload.id, payload.params);
      return data;
    },
    [PostDuplicateEdm]: async (_, id: number) => {
      const { data } = await postDuplicateEdm(id);
      return data
    },
    [PostSendEdm]: async (_, payload: SendEdmParams) => {
      const { data } = await sendEdm(payload);
      return data
    }
  }
};
