import { createStore } from "vuex";
import { auth } from "@/store/auth";
import { fabric } from "@/store/fabric";
import { collection } from "@/store/collection";
import { company } from "@/store/company";
import { edm } from "@/store/edm";
import { chatRoom } from "@/store/chatRoom";
import { AddResizer, EmitResizer, SetHeaderCollapseType } from "@/typings/store";
import { EChartsType } from "echarts/core";

type IndexState = {
  headerCollapseType: string | null;
  resizerContainer: EChartsType[]
};

const initState = (): IndexState => ({
  headerCollapseType: null,
  resizerContainer: []
});

export default createStore({
  modules: {
    auth,
    fabric,
    collection,
    company,
    edm,
    chatRoom
  },
  state: initState(),
  mutations: {
    [SetHeaderCollapseType](state, type: string) {
      state.headerCollapseType = type !== state.headerCollapseType ? type : null
    },
    [AddResizer](state, echart: EChartsType) {
      state.resizerContainer = [...state.resizerContainer, echart]
    },
    [EmitResizer](state) {
      state.resizerContainer.forEach((echart: EChartsType) => {
        echart.resize()
      })
    }
  },
  actions: {
  }
});
