import request from "@/apis/http";
import { AxiosPromise } from "axios";
import {
  EdmCardResponse,
  EdmCover,
  EdmParams,
  EdmResponse,
  SendEdmParams
} from "@/typings/edm";
import { Message } from "@/typings/common";

export function getEdms(id?: number): AxiosPromise<EdmCardResponse[]> {
  return request({
    url: "/edms/",
    method: "GET",
    params: { team: id }
  });
}

export function getEdm(id: number | string): AxiosPromise<EdmResponse> {
  return request({
    url: `/edm/${id}/`,
    method: "GET"
  });
}

export function postCreateEdm(params: EdmParams): AxiosPromise<EdmResponse> {
  return request({
    url: "/edm/",
    method: "POST",
    data: params
  });
}

export function postCreateCover(file: File): AxiosPromise<EdmCover> {
  const formData = new FormData();
  formData.append("file", file);
  return request({
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: "/edm/cover_photos/",
    method: "POST",
    data: formData
  });
}

export function postUpdateEdm(
  id: number,
  params: EdmParams
): AxiosPromise<EdmResponse> {
  return request({
    url: `/edm/${id}/`,
    method: "PUT",
    data: params
  });
}

export function deleteEdm(id: number | string): AxiosPromise<Message> {
  return request({
    url: `/edm/${id}/`,
    method: "DELETE"
  });
}

export function postDuplicateEdm(
  id: number | string
): AxiosPromise<EdmResponse> {
  return request({
    url: `/edm/${id}/duplicate/`,
    method: "POST"
  });
}

export function sendEdm(params: SendEdmParams): AxiosPromise<EdmResponse> {
  return request({
    url: `/edm/send/`,
    method: "POST",
    data: params
  });
}

export function getSentEmail(
  id: number | string
): AxiosPromise<{ recipients: string[] }> {
  return request({
    url: `/edm/${id}/sent_recipients/`,
    method: "GET"
  });
}
