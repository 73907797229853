import request from "@/apis/http";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import { CreateMembersParams, PopupDetail, UserDetail } from "@/typings/auth";
import requestEco from "@/apis/httpEco";

export type ChangePasswordParams = {
  old_password: string;
  new_password: string;
  new_password_confirm: string;
};

export type LoginParams = {
  email: string;
  password: string;
};

export type RegisterParams = {
  password: string;
  password_confirm: string;
  referral_code?: string;
  token: string;
};

export type SignInParams = {
  email: string;
  random_code: string;
};

export type SignInConfirmParams = {
  token: string;
};
export type CheckVerificationLinkParams = {
  email: string | null;
  random_code: string | null;
};

export type LoginResponse = {
  access: string;
  refresh: string;
};

export type SignInConfirmResponse = {
  message: string;
  access_token: string;
  refresh_token: string;
};

export type TokenRefreshParams = {
  refresh: string;
};

export function postCreateMembers(payload: CreateMembersParams) {
  return request({
    url: `/members/`,
    method: "POST",
    data: payload
  });
}

export function postLogin(payload: LoginParams): AxiosPromise<LoginResponse> {
  return requestEco({
    url: "/token/",
    method: "POST",
    data: payload
  });
}

export function putRegister(payload: RegisterParams) {
  const data: {
    password: string;
    password_confirm: string;
    referral_code?: string;
  } = {
    password: payload.password,
    password_confirm: payload.password_confirm
  };
  if (payload.referral_code) {
    data.referral_code = payload.referral_code;
  }

  return requestEco({
    url: "/register/",
    method: "PUT",
    data,
    headers: {
      Authorization: `Bearer ${payload.token}`
    }
  });
}

export function postVerifyEmail(payload: SignInParams) {
  return requestEco({
    url: "/verify-email/",
    method: "POST",
    data: payload
  });
}

export function getCheckEmailVerified(params: CheckVerificationLinkParams) {
  return requestEco({
    url: "/verify-email/check/",
    method: "GET",
    params
  });
}

export function getVerifyEmailConfirm(
  params: SignInConfirmParams
): AxiosPromise<SignInConfirmResponse> {
  return requestEco({
    url: "/verify-email/confirm/",
    method: "GET",
    params
  });
}

export function putChangePassword(payload: ChangePasswordParams): AxiosPromise {
  return requestEco({
    url: "/user/password/",
    method: "PUT",
    data: payload
  });
}

export function postForgotPasswordSendCode(payload: {
  email: string;
}): AxiosPromise {
  return requestEco({
    url: "/password/reset-request/",
    method: "POST",
    data: payload
  });
}

export function postForgotPasswordVerifyCode(payload: {
  email: string;
  code: string;
}): AxiosPromise {
  return requestEco({
    url: "/password/verify-code/",
    method: "POST",
    data: payload
  });
}

export function putForgotPasswordReset(payload: {
  new_password: string;
  new_password_confirm: string;
  token: string;
}): AxiosPromise {
  return requestEco({
    url: "/password/reset/",
    method: "PUT",
    data: payload,
    headers: {
      Authorization: `Bearer ${payload.token}`
    }
  });
}

// export function postTokenRefresh(
//   payload: TokenRefreshParams
// ): AxiosPromise<LoginResponse> {
//   return request({
//     url: "/token/refresh/",
//     method: "POST",
//     data: payload
//   });
// }

export function getPermission(access?: string) {
  const config: AxiosRequestConfig = {
    url: "/permission/buttons_display/",
    method: "GET"
  };
  if (access) {
    config.headers = {
      Authorization: `Bearer ${access}`
    };
  }
  return request(config);
}

export function getInvitation(key: string) {
  return request({
    url: `/invitation/${key}/`,
    method: "GET"
  });
}

export function postCreateInvitation(payload: any) {
  return request({
    url: `/invitations/`,
    method: "POST",
    data: payload
  });
}

export function getEcoUserDetail(): AxiosPromise<UserDetail> {
  return request({
    url: `/user/`,
    method: "GET"
  });
}

export function putEcoUserDetail(data: UserDetail): AxiosPromise<UserDetail> {
  const _data = {
    name: data.name,
    cellphone: data.cellphone,
    gender: data.gender,
    date_of_birth: data.date_of_birth
  };
  return request({
    url: `/user/`,
    method: "PUT",
    data: _data
  });
}

export function postSendPhoneVerifyCode(data: { phone: string; code: string }) {
  const _data = new FormData();
  _data.append("cellphone", data.phone);
  return requestEco({
    url: "/cellphone/verify-request/",
    method: "POST",
    data: _data
  });
}

export function postCheckPhoneVerifyCode(data: {
  phone: string;
  code: string;
}) {
  const _data = new FormData();
  _data.append("cellphone", data.phone);
  _data.append("code", data.code);
  return requestEco({
    url: "/cellphone/verify-code/",
    method: "POST",
    data: _data
  });
}

export function getPopups(): AxiosPromise<PopupDetail[]> {
  return requestEco({
    url: "/popups/",
    method: "GET"
  });
}
