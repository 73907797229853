// @ts-nocheck
import { Library } from "@fortawesome/fontawesome-svg-core";

// Import Fontawesome on demand
import {
  faFile,
  faUserCircle,
  faHeart as farHeart,
  faClone as farClone,
  faTimesCircle as farTimesCircle,
  faTrashAlt as farTrashAlt,
  faComment as farComment,
} from "@fortawesome/free-regular-svg-icons";

import {
  faEdit as fasEdit,
  faEye,
  faFile as fasFile,
  faHeart as fasHeart,
  faExpandAlt as fasExpandAlt,
  faUpload as fasUpload,
  faPlusCircle,
  faPlus,
  faTrashAlt,
  faUser,
  faBorderAll as fasBorderAll,
  faSquare as fasSquare,
  faCheckSquare as fasCheckSquare,
  faEllipsisH as fasEllipsisH,
  faPaperclip as fasPaperclip,
  faInfoCircle as fasInfoCircle,
  faArrowRight as fasArrowRight,
  faArrowUp as fasArrowUp,
  faArrowDown as fasArrowDown,
  faSearch as fasSearch,
  faCommentMedical as fasCommentMedical,
  faChevronLeft as fasChevronLeft,
  faImage as fasImage,
  faPlay as fasPlay,
  faLongArrowAltRight as fasLongArrowAltRight,
  faTimes as fasTimes,
  faTimesCircle as fasTimesCircle,
  faCheck as fasCheck,
  faLevelUpAlt as fasLevelUpAlt,
  faLevelDownAlt as fasLevelDownAlt,
  faChevronRight as fasChevronRight,
  faCamera as fasCamera,
  faDownload as fasDownload,
  faPen as fasPen,
  faAngleDown as fasAngleDown,
  faAngleUp as fasAngleUp,
  faFolderPlus as fasFolderPlus,
  faChartBar as fasChartBar,
  faAngleRight as fasAngleRight,
  faAngleLeft as fasAngleLeft
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import linesVisual from "echarts/types/src/chart/lines/linesVisual";

export const installIcons = (library: Library) => {
  library.add(faFile);
  library.add(faUser);
  library.add(farHeart);
  library.add(farClone);
  library.add(farTimesCircle);
  library.add(faPlusCircle);
  library.add(faEye);
  library.add(fasEdit);
  library.add(faTrashAlt);
  library.add(faPlus);
  library.add(faUserCircle);
  library.add(fasFile);
  library.add(fasBorderAll);
  library.add(fasSquare);
  library.add(fasCheckSquare);
  library.add(fasHeart);
  library.add(fasExpandAlt);
  library.add(fasUpload);
  library.add(fasPaperclip);
  library.add(faInstagram);
  library.add(fasEllipsisH);
  library.add(fasInfoCircle);
  library.add(fasArrowRight);
  library.add(fasArrowUp);
  library.add(fasArrowDown);
  library.add(fasSearch);
  library.add(fasCommentMedical);
  library.add(fasChevronLeft);
  library.add(fasImage);
  library.add(fasPlay);
  library.add(fasLongArrowAltRight);
  library.add(fasTimes);
  library.add(fasTimesCircle);
  library.add(farTrashAlt);
  library.add(fasCheck);
  library.add(fasLevelUpAlt);
  library.add(fasLevelDownAlt);
  library.add(fasChevronRight);
  library.add(farComment);
  library.add(fasCamera);
  library.add(fasDownload);
  library.add(fasPen);
  library.add(fasAngleDown);
  library.add(fasAngleUp);
  library.add(fasFolderPlus);
  library.add(fasChartBar);
  library.add(fasAngleRight);
  library.add(fasAngleLeft);
};
