import { Module } from "vuex";
import {
  GetCompanies,
  GetCompany,
  SetCompanyList,
  SetCompanyDetail
} from "@/typings/store";
import { getCompanies, getCompany } from "@/apis/company";

type CompanyState = {
  list: [];
  detail: {} | null;
};

const initState = (): CompanyState => ({
  list: [],
  detail: null
});

export const company: Module<CompanyState, any> = {
  state: initState(),
  mutations: {
    [SetCompanyList](state, data) {
      state.list = data;
    },
    [SetCompanyDetail](state, data) {
      state.detail = data;
    }
  },
  actions: {
    [GetCompanies]: async ({ commit }, payload) => {
      const { data } = await getCompanies(payload);
      commit(SetCompanyList, data);
    },
    [GetCompany]: async ({ commit }, id) => {
      const { data } = await getCompany(id);
      commit(SetCompanyDetail, data);
    }
  }
};
