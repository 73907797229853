import axios from "axios";
import {
  findToken,
  removeRefreshToken,
  removeToken
} from "@/utils/tokenStorage";
import router from "@/router";

import { ElNotification } from "element-plus";

axios.defaults.baseURL = `https://devapi.ecofabrics.world/api`;

axios.interceptors.request.use(
  config => {
    const token = findToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => error
);

axios.interceptors.response.use(
  config => config,
  async ({ response }) => {
    if (response && response.status == 401) {
      // TODO Disabled Refresh Token. Reopen 研究一下邏輯。
      if (response.data.code === "token_not_valid") {
        removeToken();
        removeRefreshToken();
        await router.push({ name: "Login" });
      }
      return Promise.reject(response);
    }

    if (response && response.status === 403) {
      ElNotification({
        type: "warning",
        title: "No permission",
        message: response.data.message || response.data.reson
      });
      return Promise.reject(response);
    }

    ElNotification({
      type: "error",
      title: "Error",
      message: response.data.message || response.data.reson
    });

    return Promise.reject(response);

    /*
     * When response code is 401, try to refresh the token.
     * Eject the interceptor so it doesn't loop in case
     * token refresh causes the 401 response
     */
    // const refresh = findRefreshToken();
    // if (!refresh) {
    //   return Promise.reject(response);
    // }

    // return postTokenRefresh({ refresh })
    //   .then(({ data }) => {
    //     setToken(data.access);
    //     setRefreshToken(data.refresh);
    //     return axios(response.config);
    //   })
    //   .catch(async error => {
    //     console.warn("refresh error", error);
    //     removeToken();
    //     removeRefreshToken();
    //     return Promise.reject(error);
    //   });
  }
);

export default axios;
