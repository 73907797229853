import { ElLoading } from "element-plus";
import { ILoadingInstance } from "element-plus/lib/el-loading/src/loading.type";

// Loading setting scope is full screen and auto scroll to top.
export function loading(): ILoadingInstance {
  const loadingInstance = ElLoading.service({ fullscreen: true });

  // 注意目前只會發揮在排除 Navigator 與 Header 之外的部分。
  const elMain = document.querySelector(".el-main");
  if (elMain) {
    elMain.scrollTo(0, 0);
  }

  return loadingInstance;
}
