import * as _ from "ramda";
import { Module } from "vuex";
import {
  GetFabrics,
  GetFabric,
  GetFabricFilters,
  SetFabricList,
  SetFabricDetail,
  SetFabricFilters,
  PostFabricStatus,
  PostAddFabricToFavorite,
  GetFabricRelatedCollections,
  SetFabricRelatedCollections,
  GetFabricAnalyzeRecentView,
  SetFabricAnalyzeRecentView,
  GetFabricAnalyzeCompanyRank,
  SetFabricAnalyzeCompanyRank,
  GetFabricAnalyzeObjectRank,
  SetFabricAnalyzeObjectRank,
  GetFabricRelatedProject,
  SetFabricRelatedProject,
  GetEcoFabric,
  PostEcoFabricToFavorite, DeleteEcoFabricToFavorite
} from "@/typings/store";
import {
  getFabricFilters,
  getFabrics,
  getFabric,
  postFabricStatus,
  postAddFabricToFavorite,
  getFabricRelatedCollections,
  getFabricAnalyzeCompanyRank,
  getFabricAnalyzeRecentView,
  getFabricAnalyzeObjectRank,
  getFabricRelatedProject,
  getEcoFabric,
  postEcoFabricToFavorite, deleteEcoFabricToFavorite
} from "@/apis/fabric";
import {
  FabricFiltersResponse,
  FabricRelatedCollectionResponse,
  FabricResult
} from "@/typings/fabric";
import { Project } from "@/typings/project";

type FabricState = {
  list: FabricResult[];
  filters: FabricFiltersResponse;
  detail: {} | null;
  relatedCollections: FabricRelatedCollectionResponse[];
  relatedProjects: Project[];
  familyFabrics: [];
  analysis: {
    recentView: FabricResult[];
    companyRank: [];
    objectRank: [];
  };
};

const initState = (): FabricState => ({
  list: [],
  filters: {
    colors: [],
    finished_specification: [],
    name: [],
    real_composition: [],
    status: [],
    weight_m2: { min: 0, max: 0 },
    width_1: { min: 0, max: 0 }
  },
  detail: null,
  relatedCollections: [],
  relatedProjects: [],
  familyFabrics: [],
  analysis: {
    recentView: [],
    companyRank: [],
    objectRank: []
  }
});

export const fabric: Module<FabricState, any> = {
  state: initState(),
  mutations: {
    [SetFabricList](state, data) {
      state.list = data;
    },
    [SetFabricFilters](state, data) {
      state.filters = data;
    },
    [SetFabricDetail](state, data) {
      state.detail = data;
    },
    [SetFabricRelatedCollections](state, data) {
      state.relatedCollections = data;
    },
    [SetFabricAnalyzeRecentView](state, data) {
      state.analysis.recentView = data.result;
      state.analysis.recentView = _.splitAt<FabricResult>(5, data.result)[0];
    },
    [SetFabricAnalyzeCompanyRank](state, data) {
      state.analysis.companyRank = data.result;
    },
    [SetFabricAnalyzeObjectRank](state, data) {
      state.analysis.objectRank = data.result;
    },
    [SetFabricRelatedProject](state, data) {
      state.relatedProjects = data;
    }
  },
  actions: {
    [GetFabricFilters]: async ({ commit }, payload) => {
      const { data } = await getFabricFilters(payload);
      commit(SetFabricFilters, data);
    },
    [GetFabrics]: async ({ commit }, payload) => {
      const { data } = await getFabrics(payload);
      commit(SetFabricList, data.results);
      return { page: payload.page || 1, pageCount: data.total_pages };
    },
    [GetFabric]: async ({ commit }, payload) => {
      const { data } = await getFabric(payload);
      commit(SetFabricDetail, data);
    },
    [GetEcoFabric]: async ({ commit }, payload) => {
      const { data } = await getEcoFabric(payload);
      console.log("eco fabric", data);
      commit(SetFabricDetail, data);
    },
    [GetFabricRelatedCollections]: async ({ commit }, payload) => {
      const { data } = await getFabricRelatedCollections(payload);
      commit(SetFabricRelatedCollections, data);
    },
    [PostFabricStatus]: async ({ commit }, payload) => {
      const { data } = await postFabricStatus(payload);
      return data;
    },
    [PostAddFabricToFavorite]: async (_, id) => {
      const { data } = await postAddFabricToFavorite(id);
      return data;
    },
    [GetFabricAnalyzeRecentView]: async ({ commit }) => {
      const { data } = await getFabricAnalyzeRecentView();
      commit(SetFabricAnalyzeRecentView, data);
    },
    [GetFabricAnalyzeCompanyRank]: async ({ commit }) => {
      const { data } = await getFabricAnalyzeCompanyRank();
      commit(SetFabricAnalyzeCompanyRank, data);
    },
    [GetFabricAnalyzeObjectRank]: async ({ commit }) => {
      const { data } = await getFabricAnalyzeObjectRank();
      commit(SetFabricAnalyzeObjectRank, data);
    },
    [GetFabricRelatedProject]: async ({ commit }, id: string) => {
      const { data } = await getFabricRelatedProject(id);
      commit(SetFabricRelatedProject, data);
    },
    [PostEcoFabricToFavorite]: async (_, id) => {
      const { data } = await postEcoFabricToFavorite(id);
      return data;
    },
    [DeleteEcoFabricToFavorite]: async (_, id) => {
      const { data } = await deleteEcoFabricToFavorite(id);
      return data;
    }
  }
};
