import * as _ from "ramda";
import {
  Filter2LayerOption,
  FilterOption,
  FilterSliderItem,
  FilterSliderOption,
  OptionItem,
  SliderItem,
  SliderOption
} from "@/typings/asideFilter";
import { FabricsParams } from "@/typings/fabric";

const formatText = (item: any[]) =>
  item.reduce((tags: string, item: any, index: number) => {
    if (index === 0) {
      return `${item.value}`;
    } else {
      return `${tags},${item.value}`;
    }
  }, "");

export const createFabricsParams = (options: any): FabricsParams => {
  const construction = options.construction
    .reduce((items: any[], item: any) => {
      const _checked = item.items.filter((item: any) => item.checked);
      return [...items, ..._checked];
    }, [])
    .filter((it: any) => it.checked);
  const composition = options.composition
    .filter((it: any) => it.checked)
    .map((it: any) => ({
      ...it,
      value: `${it.value}:[${it.range[0]}~${it.range[1]}]`
    }));
  const fabricParams: FabricsParams = {};
  const color = options.color.filter((it: any) => it.checked);
  const application = options.application.filter((it: any) => it.checked);
  const eco = options.eco.filter((it: any) => it.checked);
  const finishing = options.finishing.filter((it: any) => it.checked);
  const pattern = options.pattern.filter((it: any) => it.checked);
  const performance = options.performance.filter((it: any) => it.checked);
  const weight = options.weight;
  const width = options.width;
  const price = options.price;
  const moq = options.moq;

  if (construction.length) {
    fabricParams.construction = formatText(construction);
  }
  if (composition.length) {
    fabricParams.composition = formatText(composition);
  }
  if (color.length) {
    fabricParams.color = formatText(color);
  }
  if (application.length) {
    fabricParams.application = formatText(application);
  }
  if (eco.length) {
    fabricParams.eco = formatText(eco);
  }
  if (finishing.length) {
    fabricParams.finishing = formatText(finishing);
  }
  if (pattern.length) {
    fabricParams.pattern = formatText(pattern);
  }
  if (performance.length) {
    fabricParams.performance = formatText(performance);
  }
  if (weight.value[0] > weight.min) {
    fabricParams.weight_min = weight.value[0];
  }
  if (weight.value[1] < weight.max) {
    fabricParams.weight_max = weight.value[1];
  }
  if (width.value[0] > width.min) {
    fabricParams.width_min = width.value[0];
  }
  if (width.value[1] < width.max) {
    fabricParams.width_max = width.value[1];
  }
  if (price.value[0] > price.min) {
    fabricParams.price_min = price.value[0];
  }
  if (price.value[1] < price.max) {
    fabricParams.price_max = price.value[1];
  }
  if (moq.value) {
    fabricParams.moq = moq.value;
  }
  // material: "",
  return fabricParams;
};

export const watchFilter2LayerOptions = (items: FilterOption[]) => {
  return _.flatten(
    items.map(_option => {
      return _option.items
        .filter(item => item.checked)
        .map(item => ({
          value: item.value,
          label: item.label
        }));
    })
  );
};
export const watchFilterOptions = (items: OptionItem[]) =>
  items
    .filter(item => item.checked)
    .map(item => ({
      value: item.value,
      label: item.label
    }));

export const watchSliderOptions = (item: SliderItem) => {
  if (item.value[0] !== item.min || item.value[1] !== item.max) {
    return [
      {
        value: item.label,
        label: `${item.label} ${item.value[0]} ~ ${item.value[1]}`
      }
    ];
  }
  return [];
};

export const watchFilterSliderOptions = (options: FilterSliderItem[]) => {
  return options
    .filter(item => item.checked)
    .map(item => ({
      value: item.value,
      label:
        item.type === "range"
          ? `${item.label} ${item.range![0]}% ~ ${item.range![1]}%`
          : item.label
    }));
};

export const watchMoqOptions = (item: any) => {
  if (item.value > 0) {
    return [
      {
        value: "moq",
        label: `moq ${item.value}`
      }
    ];
  }
  return [];
};

export const closeFilter2LayerTag = (
  items: FilterOption[],
  value: any
): FilterOption[] => {
  return items.map((item: FilterOption) => ({
    ...item,
    items: item.items.map((_item: OptionItem) => ({
      ..._item,
      checked: _item.value === value ? false : _item.checked
    }))
  }));
};

export const closeFilterTag = (items: OptionItem[], value: any): OptionItem[] =>
  items.map((item: OptionItem) => ({
    ...item,
    checked: item.value === value ? false : item.checked
  }));

export const closeSliderTag = (item: SliderItem, value: any): SliderItem => {
  if (item.label === value) {
    return {
      ...item,
      value: [item.min, item.max]
    };
  }
  return item;
};

export const closeMoqTag = (item: any, value: any): { value: number } => {
  if (value === "moq") return { value: 0 };
  return item;
};

export const closeFilterSliderTag = (
  items: FilterSliderItem[],
  value: any
): FilterSliderItem[] => {
  return items.map((item: FilterSliderItem) => ({
    ...item,
    checked: item.value === value ? false : item.checked
  }));
};

export const closeAllFilter2LayerTag = (
  options: Filter2LayerOption[]
): Filter2LayerOption[] =>
  options.map(
    (option: Filter2LayerOption): Filter2LayerOption => {
      return {
        ...option,
        items: option.items.map(
          (item: FilterOption): FilterOption => {
            return {
              ...item,
              items: item.items.map(_item => ({
                ..._item,
                checked: false
              }))
            };
          }
        )
      };
    }
  );

export const closeAllFilterTags = (options: FilterOption[]): FilterOption[] =>
  options.map(
    (option: FilterOption): FilterOption => {
      return {
        ...option,
        items: option.items.map(item => ({
          ...item,
          checked: false
        }))
      };
    }
  );

export const closeAllSliderTags = (options: SliderOption[]): SliderOption[] =>
  options.map(
    (option: SliderOption): SliderOption => {
      return {
        ...option,
        checked: false,
        value: JSON.parse(JSON.stringify([option.range.min, option.range.max]))
      };
    }
  );

export const closeAllFilterSliderTags = (
  options: FilterSliderOption[]
): FilterSliderOption[] =>
  options.map(
    (option: FilterSliderOption): FilterSliderOption => {
      return {
        ...option,
        items: option.items.map(item => ({
          ...item,
          range: item.type === "range" ? [0, 100] : null,
          checked: false
        }))
      };
    }
  );

// 利用 parent_node 當作判斷標準，將資料整理成兩層的結構
export const createFilter2LayerOptions = (
  data: any,
  key: string,
  checked: any
): Filter2LayerOption[] => {
  const _checked = checked[key] || [];
  return data[key].reduce((prev: FilterOption[], it: OptionItem) => {
    const _target = prev.find(
      (_it: FilterOption) => _it.title === it.parent_node
    );

    if (_target) {
      _target.items.push({
        ...it,
        checked: _checked.includes(it.value)
      });
      return prev;
    }
    return [
      ...prev,
      {
        title: it.parent_node,
        items: [
          {
            ...it,
            checked: _checked.includes(it.value)
          }
        ]
      }
    ];
  }, []);
};

export const createFilterOptions = (
  data: any,
  key: string,
  checked: any
): OptionItem[] => {
  const _checked = checked[key] || [];
  return data[key]
    ? data[key].map((it: OptionItem) => ({
        ...it,
        checked: _checked.includes(it.value)
      }))
    : [];
};

// export const createFilterOptions = (
//   data: any,
//   params: { title: string; key: string; default?: string }[],
//   checked: any
// ): FilterOption[] =>
//   params.map(item => {
//     const _checked = checked[item.title] || [];
//     return {
//       type: "filter",
//       title: item.title,
//       items: data[item.key]
//         ? data[item.key].map((it: OptionItem) => ({
//           ...it,
//           checked: it.value === item.default || _checked.includes(it.value)
//         }))
//         : []
//     };
//   });

export const createSliderOptions = (
  data: any,
  key: string,
  checked: any
): SliderItem => {
  const target = data[key];
  return {
    label: key,
    min: target.min,
    max: target.max,
    value: [target.min, target.max]
  };

  // const _max = target
  //   ? target.checked
  //     ? target.value[1] > data[item.key].max
  //       ? target.value[1]
  //       : data[item.key].max
  //     : data[item.key].max
  //   : data[item.key].max;
  // const _min = target
  //   ? target.checked
  //     ? target.value[0] < data[item.key].min
  //       ? target.value[0]
  //       : data[item.key].min
  //     : data[item.key].min
  //   : data[item.key].min;
  // return {
  //   type: "slider",
  //   title: item.title,
  //   checked: !!target && !!target.checked,
  //   range: { min: _min, max: _max },
  //   value: target
  //     ? target.checked
  //       ? [target.value[0], target.value[1]]
  //       : [data[item.key].min, data[item.key].max]
  //     : [data[item.key].min, data[item.key].max],
  //   marks: {
  //     [_min]: `${_min}`,
  //     [_max]: `${_max}`
  //   }
  // };
};

export const createFilterSliderOptions = (
  data: any,
  key: string,
  checked: any
): FilterSliderOption[] => {
  const _checked = checked[key] || [];
  return data[key].map(
    (it: any): FilterSliderItem => {
      return {
        ...it,
        checked: _checked.includes(it.value)
      };
    }
  );
};
