import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { findToken } from "@/utils";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "@/views/home.vue")
  },
  // {
  //   path: "/landing",
  //   name: "Landing",
  //   component: () =>
  //     import(/* webpackChunkName: "Login" */ "@/views/Landing.vue")
  // },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/views/user/login.vue")
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "Register" */ "@/views/user/signin.vue")
  },
  {
    path: "/verify-email/confirm/",
    name: "RegisterConfirm",
    component: () =>
      import(
        /* webpackChunkName: "RegisterConfirm" */ "@/views/user/signin-confirm.vue"
      )
  },
  {
    path: "/verify-email/form/",
    name: "RegisterForm",
    component: () =>
      import(
        /* webpackChunkName: "RegisterForm" */ "@/views/user/signin-form.vue"
      )
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "ChangePassword" */ "@/views/user/change-password.vue"
      )
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "@/views/user/forgot-password.vue"
      )
  },
  // {
  //   path: "/user-profile/:id",
  //   name: "UserProfile",
  //   props: route => ({ id: route.params.id }),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "UserProfile" */ "@/views/Account/UserProfile.vue"
  //     )
  // },
  {
    path: "/user/profile",
    name: "UserProfile",
    component: () =>
      import(/* webpackChunkName: "UserProfile" */ "@/views/user/profile.vue")
  },
  {
    path: "/user/favorites",
    name: "UserFavorites",
    component: () =>
      import(
        /* webpackChunkName: "UserFavorites" */ "@/views/user/favorites.vue"
      )
  },
  {
    path: "/user/order/:id",
    name: "UserOrderDetail",
    props: route => ({ id: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "UserOrderDetail" */ "@/views/user/order-detail.vue"
      )
  },
  {
    path: "/user/orders",
    name: "UserOrders",
    component: () =>
      import(/* webpackChunkName: "UserOrders" */ "@/views/user/orders.vue")
  },
  {
    path: "/user/coupons",
    name: "UserCoupons",
    component: () =>
      import(/* webpackChunkName: "UserCoupons" */ "@/views/user/coupons.vue")
  },
  // {
  //   path: "/invite-brand",
  //   name: "InviteBrand",
  //   component: () =>
  //     import(/* webpackChunkName: "InviteBrand" */ "@/views/Account/Invite.vue")
  // },
  // {
  //   path: "/invite-confirm",
  //   name: "InviteConfirm",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "InviteConfirm" */ "@/views/Account/InviteConfirm.vue"
  //     )
  // },
  // {
  //   path: "/colleagues",
  //   name: "Colleagues",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "Colleagues" */ "@/views/Account/Colleagues/index.vue"
  //     )
  // },
  // {
  //   path: "/companies",
  //   name: "Companies",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "Companies" */ "@/views/Account/Companies/index.vue"
  //     )
  // },
  // {
  //   path: "/company/:id",
  //   name: "CompanyDetail",
  //   props: route => ({ id: route.params.id }),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "CompanyDetail" */ "@/views/Account/Companies/Detail.vue"
  //     )
  // },
  // {
  //   path: "/brand-insights",
  //   name: "BrandInsights",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "BrandInsights" */ "@/views/BrandInsight/index.vue"
  //     ),
  //   redirect: "/brand-insights/brand-summary",
  //   children: [
  //     {
  //       path: "brand-summary",
  //       name: "BrandSummary",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "BrandInsights" */ "@/views/BrandInsight/BrandSummary.vue"
  //         )
  //     },
  //     {
  //       path: "brand-power",
  //       name: "BrandPower",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "BrandInsights" */ "@/views/BrandInsight/BrandPower.vue"
  //         )
  //     },
  //     {
  //       path: "product-power",
  //       name: "ProductPower",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "BrandInsights" */ "@/views/BrandInsight/ProductPower.vue"
  //         )
  //     },
  //     {
  //       path: "financial-power",
  //       name: "FinancialPower",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "BrandInsights" */ "@/views/BrandInsight/FinancialPower.vue"
  //         )
  //     }
  //   ]
  // },
  // {
  //   path: "/discover-brands",
  //   name: "DiscoverBrands",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "DiscoverBrands" */ "@/views/DiscoverBrands.vue"
  //     )
  // },
  // {
  //   path: "/my-favorites",
  //   name: "MyFavorites",
  //   component: () =>
  //     import(/* webpackChunkName: "MyFavorites" */ "@/views/MyFavorites.vue")
  // },
  // {
  //   path: "/comparison",
  //   name: "Comparison",
  //   component: () =>
  //     import(/* webpackChunkName: "Comparison" */ "@/views/Comparison.vue")
  // },
  // {
  //   path: "/kyc",
  //   name: "KYC",
  //   component: () =>
  //     import(/* webpackChunkName: "KYC" */ "@/views/KYC/index.vue"),
  //   redirect: "/kyc/newsfeed",
  //   children: [
  //     {
  //       path: "newsfeed",
  //       name: "Newsfeed",
  //       component: () =>
  //         import(/* webpackChunkName: "KYC" */ "@/views/KYC/Newsfeed.vue")
  //     },
  //     {
  //       path: "social-media",
  //       name: "SocialMedia",
  //       component: () =>
  //         import(/* webpackChunkName: "KYC" */ "@/views/KYC/SocialMedia.vue")
  //     }
  //   ]
  // },
  {
    path: "/fabrics",
    name: "Fabrics",
    component: () =>
      import(/* webpackChunkName: "Fabrics" */ "@/views/fabrics/index.vue")
  },
  // {
  //   path: "/fabric/upload",
  //   name: "FabricUpload",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "FabricUpload" */ "@/views/Fabric/FabricUpload.vue"
  //     )
  // },
  // {
  //   path: "/fabric/batch-upload",
  //   name: "FabricBatchUpload",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "FabricBatchUpload" */ "@/views/Fabric/FabricBatchUpload.vue"
  //     )
  // },
  // {
  //   path: "/fabric/edit/:id",
  //   name: "FabricEdit",
  //   props: route => ({ id: route.params.id }),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "FabricEdit" */ "@/views/Fabric/FabricEdit.vue"
  //     )
  // },
  // {
  //   path: "/fabric/analysis",
  //   name: "FabricsAnalysis",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "FabricsAnalysis" */ "@/views/Fabric/FabricsAnalysis.vue"
  //     )
  // },
  {
    path: "/fabric/:id",
    name: "FabricDetail",
    props: route => ({ id: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "FabricsDetail" */ "@/views/fabrics/fabric-detail/index.vue"
      )
  },
  // // {
  // //   path: "/suppliers",
  // //   name: "Suppliers",
  // //   component: () =>
  // //     import(
  // //       /* webpackChunkName: "Suppliers" */ "@/views/Supplier/Suppliers.vue"
  // //     )
  // // },
  // // {
  // //   path: "/supplier/:id",
  // //   name: "SupplierDetail",
  // //   props: route => ({ id: route.params.id }),
  // //   component: () =>
  // //     import(
  // //       /* webpackChunkName: "SupplierDetail" */ "@/views/Supplier/SupplierDetail.vue"
  // //     )
  // // },
  // {
  //   path: "/collections",
  //   name: "Collections",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "Collections" */ "@/views/Collection/Collections.vue"
  //     )
  // },
  // {
  //   path: "/collection/analysis/:id",
  //   name: "CollectionAnalysis",
  //   props: route => ({ id: route.params.id }),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "CollectionAnalysis" */ "@/views/Collection/CollectionAnalysis.vue"
  //     )
  // },
  // {
  //   path: "/collections/analysis",
  //   name: "CollectionsAnalysis",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "CollectionsAnalysis" */ "@/views/Collection/CollectionsAnalysis.vue"
  //     )
  // },
  // {
  //   path: "/collection/:id",
  //   name: "CollectionDetail",
  //   props: route => ({ id: route.params.id }),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "CollectionDetail" */ "@/views/Collection/CollectionDetail.vue"
  //     )
  // },
  // {
  //   path: "/edm",
  //   name: "EDM",
  //   component: () => import(/* webpackChunkName: "EDM" */ "@/views/EDM/EDM.vue")
  // },
  // {
  //   path: "/edm/:id/edit",
  //   name: "EDMDetail",
  //   props: route => ({ id: route.params.id }),
  //   component: () =>
  //     import(/* webpackChunkName: "EDMDetail" */ "@/views/EDM/EDMDetail.vue")
  // },
  // {
  //   path: "/edm/:id",
  //   name: "EDMDisplay",
  //   props: route => ({ id: route.params.id }),
  //   component: () =>
  //     import(/* webpackChunkName: "EDMDisplay" */ "@/views/EDM/EDMDisplay.vue")
  // },
  // {
  //   path: "/projects",
  //   name: "Projects",
  //   component: () =>
  //     import(/* webpackChunkName: "Projects" */ "@/views/Project/Projects.vue")
  // },
  // {
  //   path: "/project/:id",
  //   name: "ProjectDetail",
  //   props: route => ({ id: route.params.id }),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "ProjectDetail" */ "@/views/Project/ProjectDetail.vue"
  //     )
  // },
  // {
  //   path: "/review",
  //   name: "Review",
  //   component: () =>
  //     import(/* webpackChunkName: "Review" */ "@/views/Review/Review.vue")
  // },
  // {
  //   path: "/review/:id",
  //   name: "ReviewDetail",
  //   props: route => ({ id: route.params.id }),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "ReviewDetail" */ "@/views/Review/ReviewDetail.vue"
  //     )
  // },
  {
    path: "/design/ai",
    name: "DesignAi",
    component: () =>
      import(/* webpackChunkName: "DesignAi" */ "@/views/designs/design-ai.vue")
  },
  {
    path: "/design/size",
    name: "DesignSize",
    component: () =>
      import(
        /* webpackChunkName: "DesignSize" */ "@/views/designs/design-size.vue"
      )
  },
  {
    path: "/stories/news",
    name: "StoryNews",
    component: () =>
      import(
        /* webpackChunkName: "StoryNews" */ "@/views/stories/store-news.vue"
      )
  },
  {
    path: `/stories/news/:id`,
    name: "StoryNewsDetail",
    props: route => ({ id: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "StoryNews" */ "@/views/stories/store-news-detail.vue"
      )
  },
  {
    path: "/stories/brand",
    name: "StoryBrand",
    component: () =>
      import(
        /* webpackChunkName: "StoryBrand" */ "@/views/stories/story-brand.vue"
      )
  },
  {
    path: "/stories/cycle",
    name: "StoryCycle",
    component: () =>
      import(
        /* webpackChunkName: "StoryCycle" */ "@/views/stories/story-cycle.vue"
      )
  },
  {
    path: "/statics/purchase-process",
    name: "PurchaseProcess",
    component: () =>
      import(
        /* webpackChunkName: "PurchaseProcess" */ "@/views/statics/purchase-process.vue"
      )
  },
  {
    path: "/statics/return-terms",
    name: "ReturnTerms",
    component: () =>
      import(
        /* webpackChunkName: "ReturnTerms" */ "@/views/statics/return-terms.vue"
      )
  },
  {
    path: "/statics/privacy-terms",
    name: "PrivacyTerms",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyTerms" */ "@/views/statics/privacy-terms.vue"
      )
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: () =>
      import(/* webpackChunkName: "ContactUs" */ "@/views/contact-us.vue")
  },
  {
    path: "/error",
    name: "Error",
    component: () => import(/* webpackChunkName: "Error" */ "@/views/error.vue")
  },
  // 捕獲所有未匹配的路由
  {
    path: '/:catchAll(.*)', // 這是 Vue Router 3.x 和 4.x 通用的捕獲所有路由語法
    name: 'NotFound',
    redirect: '/' // 自動跳轉到根路徑
    // component: () => import(/* webpackChunkName: "Home" */ "@/views/home.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

router.beforeEach(async ({ name }) => {
  // 需要登入後才看得頁面名稱列表
  const list = ["UserProfile", "Fabrics", "FabricDetail"];

  if (list.includes(name as string) && !findToken()) {
    await router.push({ name: "Login" });
  }
});

export default router;
