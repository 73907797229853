import { createApp } from "vue";

// import Normalize CSS to reset browser styles
import "normalize.css/normalize.css";
import "./index.css";

// import Element UI Library
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";

// import Fontawesome Icon Library
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

// @ts-ignore
import VueZoomer from "vue-zoomer";
// @ts-ignore
import VueShopifyBuyButton from "./initShopifyBuyButton.js";

import App from "./App.vue";
import router from "./router";
import store from "./store";

// TODO 權限設定 import { installPermission } from "@/permission";
import { installIcons } from "@/icons";

// installPermission(router);
installIcons(library);

createApp(App)
  .use(ElementPlus)
  .use(VueZoomer)
  .use(VueShopifyBuyButton)
  .use(store)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .directive("permission", {
    mounted(el: any, payload: any) {
      const key = payload.value;
      if (key === "ignore") return;
      // @ts-ignore
      const permissions = store.state.auth.permission;
      if (!permissions[key]) {
        el.style.display = "none";
      }
    }
  })
  .mount("#app");
