/** Mutations **/
// Index
export const SetHeaderCollapseType = "SET_HEADER_COLLAPSE_TYPE";
export const AddResizer = "ADD_RESIZER";
export const EmitResizer = "EMIT_RESIZER";
// Auth
export const SetPermission = "SET_PERMISSION";
// Fabric
export const SetFabricList = "SET_FABRIC_LIST";
export const SetFabricDetail = "SET_FABRIC_DETAIL";
export const SetFabricFilters = "SET_FABRIC_FILTERS";
export const SetFabricRelatedCollections = "SET_FABRIC_RELATED_COLLECTIONS";
export const SetFabricAnalyzeRecentView = "SET_FABRIC_ANALYZE_RECENT_VIEW";
export const SetFabricAnalyzeCompanyRank = "SET_FABRIC_ANALYZE_COMPANY_RANK";
export const SetFabricAnalyzeObjectRank = "SET_FABRIC_ANALYZE_OBJECT_RANK";
export const SetFabricRelatedProject = "SET_FABRIC_RELATED_PROJECT";
// Collection
export const SetCollectionList = "SET_COLLECTION_LIST";
export const SetCollectionDetail = "SET_COLLECTION_DETAIL";
export const SetCollectionGroups = "SET_COLLECTION_GROUPS";
export const SetCollectionFilters = "SET_COLLECTION_FILTERS";
export const SetOwnCollections = "SET_OWN_COLLECTIONS";
export const SetAvailableMembers = "SET_AVAILABLE_MEMBERS";
export const SetJoinedMembers = "SET_JOINED_MEMBERS";
export const SetCollectionsAnalyzeRecentView =
  "SET_COLLECTIONS_ANALYZE_RECENT_VIEW";
export const SetCollectionsAnalyzeCompanyRank =
  "SET_COLLECTIONS_ANALYZE_COMPANY_RANK";
export const SetCollectionsAnalyzeObjectRank =
  "SET_COLLECTIONS_ANALYZE_OBJECT_RANK";
export const SetCollectionAnalyzeRecentView =
  "SET_COLLECTION_ANALYZE_RECENT_VIEW";
export const SetCollectionAnalyzeCompanyRank =
  "SET_COLLECTION_ANALYZE_COMPANY_RANK";
export const SetCollectionAnalyzeObjectRank =
  "SET_COLLECTION_ANALYZE_OBJECT_RANK";
// Company
export const SetCompanyList = "SET_COMPANY_LIST";
export const SetCompanyDetail = "SET_COMPANY_DETAIL";
// EDM
export const SetEdms = "SET_EDMS";
export const SetEdm = "SET_EDM";
// Chat Room
export const SetConnectedChatRoom = "SET_CONNECTED_CHAT_ROOM";
export const SetChatRoomType = "SET_CHAT_ROOM_TYPE";
export const SetChatRoomMembers = "SET_CHAT_ROOM_MEMBERS";
export const SetChatRoomList = "SET_CHAT_ROOM_LIST";
export const SetChatRoomScrollUpMessages = "SET_CHAT_ROOM_SCROLL_UP_MESSAGES";
export const SetChatRoomScrollDownMessages =
  "SET_CHAT_ROOM_SCROLL_DOWN_MESSAGES";
export const SetChatRoomUploadMessages = "SET_CHAT_ROOM_UPLOAD_MESSAGES";
export const SetChatRoomId = "SET_CHAT_ROOM_ID";
export const SetChatRoomInfo = "SET_CHAT_ROOM_INFO";
export const ClearChatRoom = "CLEAR_CHAT_ROOM";
export const SetNotifications = "SET_NOTIFICATIONS";
export const SetSearchResults = "SET_SEARCH_RESULTS";
export const SetSearchMessage = "SET_SEARCH_MESSAGE";
export const IncreaseConnectCount = "INCREASE_CONNECT_COUNT";
export const SetChatRoomUnreadCount = "SET_CHAT_ROOM_UNREAD_COUNT";
export const SetNotificationUnreadCount = "SET_NOTIFICATION_UNREAD_COUNT";

/** Actions **/
// Auth
export const Login = "LOGIN";
export const Logout = "LOGOUT";
export const Signin = "SIGNIN";
export const ChangePassword = "CHANGE_PASSWORD";
export const GetPermission = "GET_PERMISSION";
export const SetIsLogin = "SET_IS_LOGIN";
// Fabric
export const GetFabrics = "GET_FABRICS";
export const GetFabric = "GET_FABRIC_DETAIL";
export const GetEcoFabric = "GET_ECO_FABRIC";
export const GetFabricFilters = "GET_FABRIC_FILTERS";
export const GetFabricRelatedCollections = "GET_FABRIC_RELATED_COLLECTIONS";
export const GetFabricRelatedProject = "GET_FABRIC_RELATED_PROJECT";
export const PostFabricStatus = "POST_FABRIC_STATUS";
export const PostAddFabricToFavorite = "POST_ADD_FABRIC_TO_FAVORITE";
export const PostEcoFabricToFavorite = "POST_ECO_FABRIC_TO_FAVORITE";
export const DeleteEcoFabricToFavorite = "DELETE_ECO_FABRIC_TO_FAVORITE";
export const GetFabricAnalyzeRecentView = "GET_FABRIC_ANALYZE_RECENT_VIEW";
export const GetFabricAnalyzeCompanyRank = "GET_FABRIC_ANALYZE_COMPANY_RANK";
export const GetFabricAnalyzeObjectRank = "GET_FABRIC_ANALYZE_OBJECT_RANK";
// Collection
export const GetCollections = "GET_COLLECTIONS";
export const GetCollection = "GET_COLLECTION";
export const GetCollectionGroups = "GET_COLLECTION_GROUPS";
export const GetCollectionFilters = "GET_COLLECTION_FILTERS";
export const GetOwnCollections = "GET_OWN_COLLECTIONS";
export const PostCollectionDuplicate = "POST_COLLECTION_DUPLICATE";
export const PostCreateCollection = "POST_CREATE_COLLECTION";
export const PostUpdateCollection = "POST_UPDATE_COLLECTION";
export const PostCollectionCreateFabric = "POST_COLLECTION_CREATE_FABRIC";
export const DeleteCollectionFabric = "DELETE_COLLECTION_FABRIC";
export const PostCollectionUpdateFabricPrice =
  "POST_COLLECTION_UPDATE_FABRIC_PRICE";
export const GetAvailalbeMembers = "GET_AVAILABLE_MEMBERS";
export const GetJoinedMembers = "GET_JOINED_MEMBERS";
export const PostUpdateJoinedMembers = "POST_UPDATE_JOINED_MEMBERS";
export const GetCollectionsAnalyzeRecentView =
  "GET_COLLECTIONS_ANALYZE_RECENT_VIEW";
export const GetCollectionsAnalyzeCompanyRank =
  "GET_COLLECTIONS_ANALYZE_COMPANY_RANK";
export const GetCollectionsAnalyzeObjectRank =
  "GET_COLLECTIONS_ANALYZE_OBJECT_RANK";
export const GetCollectionAnalyzeRecentView =
  "GET_COLLECTION_ANALYZE_RECENT_VIEW";
export const GetCollectionAnalyzeCompanyRank =
  "GET_COLLECTION_ANALYZE_COMPANY_RANK";
export const GetCollectionAnalyzeObjectRank =
  "GET_COLLECTION_ANALYZE_OBJECT_RANK";
// Company
export const GetCompanies = "GET_COMPANIES";
export const GetCompany = "GET_COMPANY";
// EDM
export const GetEdms = "GET_EDMS";
export const GetEdm = "GET_EDM";
export const PostCreateEdm = "POST_CREATE_EDM";
export const PostUpdateEdm = "POST_UPDATE_EDM";
export const PostDuplicateEdm = "POST_DUPLICATE_EDM";
export const PostSendEdm = "POST_SEND_EDM";
// Chat Room
export const ConnectChatRoom = "CONNECT_CHAT_ROOM";
export const DisconnectChatRoom = "DISCONNECT_CHAT_ROOM";
export const GetChatRoomMembers = "GET_CHAT_ROOM_MEMBERS";
export const GetChatRoomList = "GET_CHAT_ROOM_LIST";
export const CreateChatRoomRoom = "CREATE_CHAT_ROOM_ROOM";
export const GetChatRoomInfo = "GET_CHAT_ROOM_INFO";
export const GetChatRoomMessages = "GET_CHAT_ROOM_MESSAGES";
export const GetChatRoomScrollUp = "GET_CHAT_ROOM_SCROLL_UP";
export const GetChatRoomScrollDown = "GET_CHAT_ROOM_SCROLL_DOWN";
export const PostChatRoomRead = "POST_CHAT_ROOM_READ";
export const PostChatRoomReadFirstTime = "POST_CHAT_ROOM_READ_FIRST_TIME";
export const PostNotifications = "POST_NOTIFICATIONS";
export const PostNotificationReadFirstTime =
  "POST_NOTIFICATION_READ_FIRST_TIME";
export const PostNotificationRead = "POST_NOTIFICATION_READ";
export const PostNotificationReadByProject =
  "POST_NOTIFICATION_READ_BY_PROJECT";
export const PostChatRoomTitle = "POST_CHAT_ROOM_TITLE";
export const GetChatRoomSearchResults = "GET_CHAT_ROOM_SEARCH_RESULTS";
export const GetChatRoomSearchMessages = "GET_CHAT_ROOM_SEARCH_MESSAGES";
